.card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  max-width: 381px;
  width: 100%;
  min-height: 284px;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center;

  padding: 10px 20px;
  background-color: #F1F3FF;
  box-shadow: 0 1px 2px 0 rgba(89, 103, 209, 0.06), 0 4px 4px 0 rgba(89, 103, 209, 0.05), 0 9px 5px 0 rgba(89, 103, 209, 0.03), 0 15px 6px 0 rgba(89, 103, 209, 0.01), 0 24px 7px 0 rgba(89, 103, 209, 0);

  @media (max-width: 1260px) {
    max-width: 300px;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    min-height: 235px;

    &:nth-child(5) {
      max-width: 350px;
      grid-column: span 2;
      justify-self: center;
    }
  }

  @media (max-width: 767px) {
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    min-height: 235px;
    justify-content: space-between;

    padding: 10px 12px;

    &:nth-child(5) {
      max-width: 164px;
      grid-column: span 2;
      justify-self: center;
    }
  }
}

.title {
  color: #5967D1;
  font-weight: 600;
  font-size: 27px;
  line-height: 1.3;

  @media (max-width: 1270px) {
    font-size: 22px;
  }

  @media (max-width: 910px) {
    font-size: 18px;
  }
}

.image {
  img {
    max-width: 160px;

    @media (max-width: 767px) {
      max-width: 120px;
    }
  }
}
