.footer {
  background-color: white;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;

  &-box {
    max-width: 1920px;
    width: 1920px;
    display: flex;
    flex-direction: column;

    &__desc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &-text {
        margin-left: 45px;
        max-width: 700px;
      }

      p {
        font-size: 20px;
        line-height: 130%;
        margin-bottom: 10px;
      }

      &-network {
        margin-right: 45px;
        .network-images {
          display: flex;
          flex-direction: row;
          gap: 25px !important;
          margin-bottom: 30px;
        }
      }
    }

    &__nav {
      margin: 70px 45px 0 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      nav {
        display: flex;
        flex-direction: row;
        gap: 40px;
      }
    }
  }

  a {
    color: black;
  }

  .image {
    &-logoFooter {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1720px) {
  .footer {
    margin-top: 50px;

    &-box {
      max-width: 1720px;
      width: 1720px;

      &__desc {
        &-text {
          margin-left: 45px;
          max-width: 600px;
        }

        p {
          font-size: 18px;
          margin-bottom: 4px;
        }

        &-network {
          margin-right: 45px;
          .network-images {
            display: flex;
            flex-direction: row;
            gap: 48px;
            margin-bottom: 25px;
          }
        }
      }

      &__nav {
        margin: 40px 25px 0 30px;
      }
    }
  }
}

@media (max-width: 1505px) {
  .footer {
    margin-top: 40px;
    margin-bottom: 50px;
    &-box {
      max-width: 1505px;
      &__desc {
        &-text {
          margin-left: 30px;
          max-width: 600px;
        }

        &-network {
          margin-right: 30px;
        }

        p {
          font-size: 15px;
          max-width: 460px;
        }
      }
    }

    &__nav {
      margin: 50px 30px 0 30px;
      gap: 8px;
      flex-direction: column;
      align-items: center;

      nav {
        display: flex;
        flex-direction: row;
        gap: 40px;
      }
    }
  }

  .image {
    &-logoFooter {
      max-width: 200px;
      margin-bottom: 10px;
    }

    &-network {
      max-width: 35px;
      max-height: 35px;
    }
  }
}

@media (max-width: 1180px) {
  .footer {
    margin-top: 5px;
    margin-bottom: 30px;
    &-box {
      max-width: 1180px;
      &__desc {
        &-text {
          max-width: 300px !important;
        }

        p {
          font-size: 14px;
          max-width: 700px;
          margin-bottom: 1px;
        }

        &-network {
          .network-images {
            gap: 20px !important;
            margin-bottom: 15px;
          }
        }
      }

      &__nav {
        margin: 35px 30px 0 30px;
        gap: 8px;
        flex-direction: column;
        align-items: center;

        nav {
          flex-direction: row;
          gap: 15px;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 830px) {
  .footer {
    margin-top: -20px;
    &-box {
      max-width: 830px;
      &__desc {
        &-text {
          max-width: 300px !important;
        }

        p {
          font-size: 12px;
        }

        &-network {
          .network-images {
            gap: 10px;
          }
        }
      }

      &__nav {
        gap: 1px;
        nav {
          gap: 9px;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .footer {
    &-box {
      max-width: 720px;
      &__desc {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 0;
          max-width: 450px !important;
        }

        p {
          font-size: 12px;
          text-align: center;
        }

        &-network {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 0px;
          .network-images {
            gap: 10px !important;
            margin-bottom: 10px !important;
          }
        }
      }

      &__nav {
        margin-top: 35px;
        gap: 5px;
        flex-direction: column;
        align-items: center;

        nav {
          gap: 9px;

          a {
            text-align: center;
          }
        }
      }
    }
    .image {
      &-network {
        margin-top: 20px;
        max-width: 30px;
        max-height: 30px;
      }
    }
  }
}

@media (max-width: 550px) {
  .footer {
    &-box {
      max-width: 550px;
      &__desc {
        &-text {
          max-width: 350px !important;
        }

        p {
          font-size: 11px;
        }

        &-network {
          margin-right: 0px;
          .network-images {
            gap: 10px !important;
            margin-bottom: 10px !important;
          }
        }
      }

      &__nav {
        margin-top: 35px;
        gap: 5px;

        nav {
          gap: 5px;

          a {
            text-align: center;
          }
        }
      }
    }
    .image {
      &-logoFooter {
        max-width: 180px;
        margin-bottom: 5px;
      }

      &-network {
        margin-top: 20px;
        max-width: 22px;
        max-height: 22px;
      }
    }
  }
}

@media (max-width: 450px) {
  .footer {
    &-box {
      max-width: 450px;
      &__desc {
        &-text {
          max-width: 250px !important;
        }

        p {
          font-size: 10px;
        }
      }

      &__nav {
        margin-top: 25px;
      }
    }
    .image {
      &-logoFooter {
        max-width: 140px;
        margin-bottom: 5px;
      }

      &-network {
        margin-top: 20px;
        max-width: 22px;
        max-height: 22px;
      }
    }
  }
}
