.wrapper {
  padding: 60px 0;

  @media (max-width: 1220px) {
    padding: 120px 0 60px 0;
  }

  @media (max-width: 610px) {
    padding: 60px 0;
  }
}

.inner {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;

  @media (max-width: 1649px) {
    grid-row-gap: 50px;


    //grid-template-columns: repeat(auto-fit, minmax(510px, 1fr));
    //justify-content: center;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 100%;

  border: 1px solid #5967D1;
  border-radius: 20px;
  min-height: 152px;
  padding: 10px 40px;
  box-sizing: border-box;

  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;

  //@media (max-width: 1649px) {
  //  &:nth-child(3) {
  //    grid-column: span 2;
  //    justify-self: center;
  //    max-width: 600px;
  //  }
  //}

  @media (max-width: 1270px) {
    font-size: 22px;
    min-height: 100px;
  }

  @media (max-width: 767px) {
    padding: 10px 40px;
    min-height: auto;
  }

  @media (max-width: 374px) {
    padding: 10px 40px;
    min-height: auto;
    font-size: 16px;
  }
}

.text {
  span {
    color: #5967D1;
  }
}

.number {
  position: absolute;
  left: -25px;
  top: -31px;

  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #5967D1;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.2;
  border: 1px solid #5967D1;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(144, 153, 225, 0.2), 0 6px 6px 0 rgba(144, 153, 225, 0.17), 0 13px 8px 0 rgba(144, 153, 225, 0.1), 0 22px 9px 0 rgba(144, 153, 225, 0.03), 0 35px 10px 0 rgba(144, 153, 225, 0);

  @media (max-width: 1270px) {
    left: -11px;
    top: -33px;

    width: 54px;
    height: 54px;

    font-size: 26px;
  }
}

