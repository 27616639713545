.baseButton {
  border: none;
  border-radius: 100px;
  background-color: rgb(89, 103, 209);
  color: rgb(255, 255, 255);
  padding: 18px;
  font-size: 21px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: rgb(67, 78, 157);
  }
}
