.title {
  font-size: 36px;
  line-height: 1.4;
  font-weight: 600;
  color: #EA5D2F;

  @media (max-width: 1145px) {
    font-size: 28px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.wrapper {
  padding-top: 60px;

  @media (max-width: 1024px) {
    padding-top: 28px;
  }
}

.header {
  @media (max-width: 1340px) {
    margin-bottom: 20px;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}

.card {
  box-sizing: border-box;
  max-width: 898px;
  padding: 10px 20px;
  box-shadow: 0 1px 2px 0 rgba(89, 103, 209, 0.06), 0 4px 4px 0 rgba(89, 103, 209, 0.05), 0 9px 5px 0 rgba(89, 103, 209, 0.03), 0 15px 6px 0 rgba(89, 103, 209, 0.01), 0 24px 7px 0 rgba(89, 103, 209, 0);
  background-color: #fff;
  border-radius: 20px;
}

.tag {
  display: inline-block;
  font-size: 36px;
  padding: 0 20px;
  margin-bottom: 10px;
  background-color: #EA5D2F;
  border-radius: 100px;
  color: #fff;

  @media (max-width: 1210px) {
    font-size: 28px;
  }

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

.text {
  font-size: 27px;

  @media (max-width: 1210px) {
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.imageWrapper {
  @media (max-width: 1024px) {
    display: none;
  }
}
