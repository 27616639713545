.header {
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
}

.box {
  margin-top: 40px;
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr 1fr;
  //gap: 28px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 28px;
}

.title {
  font-size: 40px;
  line-height: 1.4;
  font-weight: 600;
  color: #5967D1;

  @media (max-width: 1145px) {
    font-size: 28px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
  }
}

.slideWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 767px) {
    row-gap: 16px;
    column-gap: 15px;
  }
}

.sliderController {
  cursor: pointer;
  background-color: transparent;
  border: none;

  img {
    width: 48px;
    height: 48px;

    @media (max-width: 1024px) {
      width: 28px;
      height: 28px;
    }
  }
}


.isShowPrev {
  display: none;
}

.isShowNext {
  display: none;
}
