.checkboxSquare{
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkboxSquare+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.checkboxSquare+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.checkboxSquare:checked+label::before {
  border-color: #ffffff;
  background-color: #ffffff;
  background-image: url("/src/assets/icons/IconCheck.svg")
}

.checkboxSquare:not(:disabled):not(:checked) + label:hover::before {
  border-color: #b3d7ff;
}

.checkboxSquare:not(:disabled):active + label::before {
  background-color: #d0b3ff;
  border-color: #d0b3ff;
}

.checkboxSquare-label{
  color: rgb(255, 255, 255);
  font-size: 29px;
  font-weight: 500;
  line-height: 140%;
  }

  @media (max-width: 1570px) {
    .checkboxSquare-label{
      font-size: 22px;
      line-height: 130%;
      }
  }

  @media (max-width: 1245px) {
    .checkboxSquare-label{
      font-size: 17px;
      line-height: 120%;
      }
  }

  @media (max-width: 490px) {
    .checkboxSquare-label{
      font-size: 15px;
      line-height: 100%;
      }

      .checkboxSquare-box {
        margin-bottom: 8px !important;
      }
  }


  @media (max-width: 375px) {
    .checkboxSquare-label{
      font-size: 14px;
      line-height: 100%;
      }
  }