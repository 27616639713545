.container {
  max-width: 1668px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 0 24px;
  }

  @media (max-width: 767px) {
    padding: 0 16px;
  }
}
