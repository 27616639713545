.headerMenu {
  display: none;
}

.showMenu {
  right: 0 !important;
  transition: 0.4s;
  overflow-y: scroll;
}

@media (max-width: 750px) {
  .headerMenu {
    display: flex;
    height: 100%;
    width: 100%;
    background: #0f112f;
    right: -1000px;
    transition: 0.4s;
    top: 0;
    position: fixed;
    border: none;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999;

    &-nav {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-top: 100px;
      gap: 20px;
      a {
        width: 100%;
        color: white;
        line-height: 140%;
        font-size: 24px;
        position: relative;
        z-index: 15;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 400;
    }

    span {
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 400;
      padding: 0 0 0 0;
    }

    .mailHeader {
      width: 100%;
      color: rgb(255, 255, 255);
      font-size: 17px;
      font-weight: 400;
    }

    .headerMenu-btn {
      margin: 40px auto 0 auto;
      position: relative;
      z-index: 15;
      max-width: 300px;
      width: 300px;
      max-height: 70px;
      height: 40px;
      background-color: white !important;
      color: rgb(89, 103, 209) !important;
      font-size: 15px;
      padding: 7px;
    }

    &-bottom {
      margin: 40px auto 0 auto;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      &__contacts {
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      &__network {
        gap: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        a {
          width: auto;
        }
      }
    }

    .img {
      &-BgMenu {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
      }
      &-logoBgMenu {
        width: 200px;
        z-index: 1500;
        position: absolute;
        top: 0;
        left: 0;
        margin: 15px 30px;
      }

      &-network {
        height: 40px;
        width: 40px;

        &:last-child {
          height: 30px;
          width: 30px;
        }
      }
    }

    &-dropdown {
      p,
      a {
        margin-bottom: 0;
        font-size: 24px;
      }

      &__head {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        justify-content: center;

        img {
          margin-left: 8px;
        }
      }

      &__body {
        margin-top: 10px;
        margin-left: 0;
        opacity: 0;
        z-index: -1;
        transition: 0.4s;
        pointer-events: none;
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin-left: 5px;
      }

      &--active {
        opacity: 1;
        z-index: 2;
        transition: 0.4s;
        pointer-events: all;
        position: relative;
      }
    }
  }
}

@media (max-width: 450px) {
  .headerMenu {
    display: flex;
    height: 100%;
    width: 100%;
    background: #0f112f;
    right: -1000px;
    top: 0;
    position: fixed;
    border: none;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999;

    &-nav {
      display: flex;
      flex-direction: column;
      margin-top: 105px;
      gap: 13px;

      a {
        color: white;
        line-height: 140%;
        font-size: 25px;
        position: relative;
        z-index: 15;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-weight: 400;
    }

    span {
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 400;
    }

    .mailHeader {
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-weight: 400;
    }

    &-bottom {
      &__network {
        gap: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    .img {
      &-BgMenu {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
      }
      &-logoBgMenu {
        width: 200px;
        z-index: 1500;
        position: absolute;
        top: 0;
        left: 0;
        margin: 18px 24px;
      }

      &-network {
        height: 40px;
        width: 40px;

        &:last-child {
          height: 30px;
          width: 30px;
        }
      }
    }

    &-dropdown {
      p,
      a {
        margin-bottom: 0;
        font-size: 25px;
      }

      &__body {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 360px) {
  .headerMenu {
    &-nav {
      margin-top: 95px;
      gap: 10px;
      a {
        color: white;
        line-height: 140%;
        font-size: 23px;
        position: relative;
        z-index: 15;
      }
    }

    .headerMenu-btn {
      margin: 30px auto 0 auto;

      max-width: 200px;
      width: 200px;
      max-height: 70px;
      height: 40px;

      font-size: 14px;
      padding: 7px;
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 12px;
    }

    span {
      color: rgb(255, 255, 255);
      font-size: 12px;

      padding: 0 0 0 0;
    }

    .mailHeader {
      color: rgb(255, 255, 255);
      font-size: 12px;
      font-weight: 400;
    }

    &-bottom {
      &__network {
        gap: 0px;
        justify-content: flex-start;
      }
    }

    &-dropdown {
      &__head {
        margin-left: 0;
      }
      p,
      a {
        margin-bottom: 0;
        font-size: 23px;
      }

      &__body {
        margin-left: 0;
      }
    }
  }

  .img {
    &-BgMenu {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 290px;
    }
    &-logoBgMenu {
      width: 150px !important;
      z-index: 1500;
      position: absolute;
      top: 0;
      left: 0;
      margin: 20px 21px !important;
    }

    &-network {
      height: 30px;
      width: 30px;

      &:last-child {
        height: 20px;
        width: 20px;
      }
    }
  }
}
