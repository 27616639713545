@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-style: normal;
  text-decoration: none;
  font-family: "Nunito", sans-serif;
}

body{
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}


