.portfolio {
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  width: 100%;
  &-box {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 105px;
    max-width: 1920px;
    width: 1920px;

    h1 {
      color: black;
      margin-bottom: -70px;
      margin-left: 45px;
    }

    a {
      color: rgb(89, 103, 209);
    }

    h2 {
      color: rgb(89, 103, 209);
      font-size: 37px;
      line-height: 115%;
      margin-bottom: 30px;
    }
    h3 {
      color: black;
      font-size: 27px;
      margin-bottom: 15px;
    }

    &__slider {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 70px;

      &-body {
        margin-right: 285px;
        max-width: 600px;
        ul {
          display: flex;
          flex-direction: column;
          gap: 5px;
          li {
            font-size: 24px;
            list-style-type: disc;
            margin-left: 25px;
          }
        }

        .body-bottomSlide {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          gap: 10px;

          span {
            color: #ea5d2f;
            font-size: 27px;
            font-weight: 600;
            line-height: 140%;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 75px;
    }
  }

  &-link {
    position: relative;
    z-index: 4;
  }
}
.bottom-slide-body {
  margin: 0 0 0 170px;
}

.button {
  &-sliderItem {
    margin-top: 20px;
    width: 390px;
    cursor: pointer;
    transition: 0.5s;
  }

  &-imageItem {
    opacity: 0;
    pointer-events: none;
    width: 65% !important;
    padding: 16px;
    font-size: 16px;
    position: absolute;
    top: 270px;
    left: 140px;
    z-index: 50;
    background-color: transparent;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background-color: #5967d132;
    }
  }

  &-active {
    opacity: 1;
    pointer-events: all;
    transition: 0.4s;
  }

  &-portfolio {
    background-color: white;
    border: 2px solid rgb(89, 103, 209);
    color: rgb(89, 103, 209);
    text-transform: none !important;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background-color: white;
      border: 2px solid rgb(89, 103, 209);
      color: rgb(89, 103, 209);
      cursor: pointer;
      transition: 0.5s;
    }
  }
}

.blackout-portfolio {
  z-index: 50;
  transition: 0.4s;

  &:after {
    content: "";
    background: rgba(15, 17, 47, 0.516);
    border-radius: 18px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.image {
  &-slide {
    display: block;
    width: 900px;
    z-index: 5;
  }

  &-portfolioArrow {
    width: 550px;
    &:last-child {
      transform: rotate(180deg);
    }
  }
}

.swiper {
  width: 100%;

  &-button-next {
    margin: 0 40px;
    cursor: pointer;
    transition: 0.5s;
    background-color: #5967d194;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50px;

    &:hover {
      cursor: pointer;
      transition: 0.5s;
      background-color: #5967d1;
    }

    &::after {
      color: white;
      font-size: 26px !important;
    }
  }

  &-button-prev {
    margin: 0 40px;
    cursor: pointer;
    transition: 0.5s;
    background-color: #5967d194;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50px;

    &:hover {
      cursor: pointer;
      transition: 0.5s;
      background-color: #5967d1;
    }

    &::after {
      color: white;
      font-size: 26px !important;
    }
  }
}

@media (max-width: 1720px) {
  .portfolio {
    &-box {
      gap: 75px;
      max-width: 1720px;
      width: 1720px;

      h1 {
        color: black;
        margin-bottom: -60px;
        margin-left: 42px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 30px;
        margin-bottom: 26px;
      }
      h3 {
        color: black;
        font-size: 23px;
        margin-bottom: 15px;
      }

      &__slider {
        gap: 70px;

        &-body {
          margin-right: 225px;
          max-width: 600px;
          ul {
            gap: 5px;
            li {
              font-size: 21px;
              margin-left: 25px;
            }
          }

          .body-bottomSlide {
            margin-top: 15px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 23px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
      }
    }
  }
  .bottom-slide-body {
    margin: 0 0 0 185px;
  }

  .button {
    &-sliderItem {
      margin-top: 20px;
      width: 370px !important;
      cursor: pointer;
      transition: 0.5s;
      font-size: 19px;
      width: 38px;
      padding: 13px;
    }

    &-imageItem {
      width: 65% !important;
      padding: 16px;
      font-size: 16px;
      position: absolute;
      top: 250px;
      left: 150px;
    }
  }

  .image {
    &-slide {
      width: 850px;
    }

    &-portfolioArrow {
      width: 500px;
      &:last-child {
        transform: rotate(180deg);
      }
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      margin: 0 55px;
      width: 60px !important;
      height: 60px !important;
      border-radius: 50px;
    }

    &-button-prev {
      margin: 0 55px;
      background-color: #5967d194;
      width: 60px !important;
      height: 60px !important;
      border-radius: 50px;
    }
  }
}

@media (max-width: 1540px) {
  .portfolio {
    &-box {
      gap: 55px;
      max-width: 1540px;
      width: 1540px;

      h1 {
        color: black;
        margin-bottom: -30px;
        margin-left: 40px;
        font-size: 36px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 22px;
        margin-bottom: 20px;
      }
      h3 {
        color: black;
        font-size: 20px;
        margin-bottom: 15px;
      }

      &__slider {
        gap: 60px;

        &-body {
          margin-right: 245px;
          max-width: 600px;
          ul {
            gap: 5px;
            li {
              font-size: 18px;
              margin-left: 25px;
            }
          }

          .body-bottomSlide {
            margin-top: 15px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 20px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
      }
    }
  }
  .bottom-slide-body {
    margin: 0 0 0 165px;
  }

  .button {
    &-sliderItem {
      margin-top: 20px;
      width: 340px !important;
      padding: 13px;
      font-size: 16px;
      cursor: pointer;
      transition: 0.5s;
    }

    &-imageItem {
      width: 60% !important;
      padding: 14px;
      font-size: 14px;
      position: absolute;
      top: 210px;
      left: 135px;
    }
  }

  .image {
    &-slide {
      width: 720px;
    }

    &-portfolioArrow {
      width: 400px;
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      margin: 0 65px;
      width: 45px !important;
      height: 45px !important;
      border-radius: 50px;
      &::after {
        font-size: 20px !important;
      }
    }

    &-button-prev {
      margin: 0 65px;
      width: 45px !important;
      height: 45px !important;
      border-radius: 50px;

      &::after {
        font-size: 20px !important;
      }
    }
  }
}

@media (max-width: 1441px) {
  .portfolio {
    &-box {
      gap: 45px;
      max-width: 1440px;

      h1 {
        color: black;
        margin-bottom: -30px;
        margin-left: 42px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 19px;
        margin-bottom: 13px;
      }
      h3 {
        color: black;
        font-size: 19px;
        margin-bottom: 10px;
      }

      &__slider {
        display: flex;
        align-items: center;
        gap: 40px;

        &-body {
          margin-right: 250px;
          max-width: 600px;
          ul {
            gap: 5px;
            li {
              font-size: 18px;
              margin-left: 25px;
            }
          }

          .body-bottomSlide {
            margin-top: 15px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 19px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
      }
    }
  }
  .bottom-slide-body {
    margin: 0 0 0 145px;
  }

  .blackout-portfolio {
    z-index: 50;
    transition: 0.4s;
  }

  .button {
    &-sliderItem {
      margin-top: 20px;
      width: 340px !important;
      padding: 13px;
      font-size: 16px;
      cursor: pointer;
      transition: 0.5s;
    }

    &-imageItem {
      width: 60% !important;
      padding: 14px;
      font-size: 14px;
      position: absolute;
      top: 200px;
      left: 125px;
    }

    &-portfolio {
      font-size: 19px;
      padding: 11px;
    }
  }

  .image {
    &-slide {
      width: 670px;
    }

    &-portfolioArrow {
      width: 300px;
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      margin: 0 35px;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50px;
      &::after {
        font-size: 19px !important;
      }
    }

    &-button-prev {
      margin: 0 35px;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50px;

      &::after {
        font-size: 19px !important;
      }
    }
  }
}

@media (max-width: 1270px) {
  .portfolio {
    &-box {
      gap: 45px;
      max-width: 1270px;

      h1 {
        color: black;
        margin-bottom: -30px;
        margin-left: 40px;
        font-size: 32px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 17px;
        margin-bottom: 10px;
      }
      h3 {
        color: black;
        font-size: 18px;
        margin-bottom: 7px;
      }

      &__slider {
        display: flex;
        align-items: center;
        gap: 40px;

        &-body {
          margin-right: 205px;
          max-width: 550px;
          ul {
            gap: 4px;
            li {
              font-size: 16px;
              margin-left: 25px;
            }
          }

          .body-bottomSlide {
            margin-top: 10px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 18px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
      }
    }
  }
  .bottom-slide-body {
    margin: 0 0 0 155px;
  }

  .button {
    &-sliderItem {
      margin-top: 14px;
      width: 300px !important;
      padding: 12px;
      font-size: 14px;
      cursor: pointer;
      transition: 0.5s;
    }

    &-imageItem {
      width: 60% !important;
      padding: 12px;
      font-size: 14px;
      position: absolute;
      top: 165px;
      left: 115px;
    }

    &-portfolio {
      font-size: 16px;
      padding: 10px;
    }
  }

  .image {
    &-slide {
      width: 590px;
      border-radius: 15px;
    }

    &-portfolioArrow {
      width: 250px;
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      margin: 0 35px;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50px;
      &::after {
        font-size: 19px !important;
      }
    }

    &-button-prev {
      margin: 0 35px;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50px;

      &::after {
        font-size: 19px !important;
      }
    }
  }
}

@media (max-width: 1145px) {
  .portfolio {
    &-box {
      gap: 35px;
      max-width: 1145px;

      h1 {
        color: black;
        margin-bottom: -20px;
        margin-left: 40px;
        font-size: 28px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 15px;
        margin-bottom: 10px;
      }
      h3 {
        color: black;
        font-size: 14px;
        margin-bottom: 7px;
      }

      &__slider {
        display: flex;
        align-items: center;
        gap: 40px;

        &-body {
          margin-right: 207px;
          max-width: 550px;
          ul {
            gap: 4px;
            li {
              font-size: 14px;
              margin-left: 20px;
            }
          }

          .body-bottomSlide {
            margin-top: 10px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 13px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
      }
    }
  }
  .bottom-slide-body {
    margin: 0 0 0 135px;
  }

  .button {
    &-sliderItem {
      margin-top: 14px;
      width: 280px !important;
      padding: 10px;
      font-size: 12px;
      cursor: pointer;
      transition: 0.5s;
    }

    &-imageItem {
      width: 60% !important;
      padding: 12px;
      font-size: 13px;
      position: absolute;
      top: 145px;
      left: 95px;
    }

    &-portfolio {
      font-size: 13px;
      padding: 10px;
    }
  }

  .image {
    &-slide {
      width: 515px;
      border-radius: 15px;
    }

    &-portfolioArrow {
      width: 250px;
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      margin: 0 35px;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50px;
      &::after {
        font-size: 19px !important;
      }
    }

    &-button-prev {
      margin: 0 35px;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50px;

      &::after {
        font-size: 19px !important;
      }
    }
  }
}

@media (max-width: 910px) {
  .portfolio {
    margin-top: 50px;
    &-box {
      align-items: center;
      gap: 35px;
      max-width: 910px;

      h1 {
        color: black;
        margin-bottom: -20px;
        margin-left: 0;
        font-size: 26px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 14px;
        margin-bottom: 10px;
      }
      h3 {
        color: black;
        font-size: 12px;
        margin-bottom: 7px;
      }

      &__slider {
        display: flex;
        align-items: center;
        gap: 40px;

        &-body {
          margin-right: 125px;
          max-width: 550px;
          ul {
            gap: 4px;
            li {
              font-size: 11px;
              margin-left: 12px;
            }
          }

          .body-bottomSlide {
            margin-top: 10px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 12px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
        width: 100%;
      }
    }
  }
  .bottom-slide-body {
    margin: 0 0 0 100px;
  }

  .button {
    &-sliderItem {
      margin-top: 10px;
      width: 240px !important;
      padding: 10px;
      font-size: 11px;
      cursor: pointer;
      transition: 0.5s;
    }

    &-imageItem {
      width: 65% !important;
      padding: 11px;
      font-size: 12px;
      position: absolute;
      top: 115px;
      left: 70px;
    }

    &-portfolio {
      font-size: 11px;
      padding: 9px;
    }
  }

  .image {
    &-slide {
      width: 420px;
      border-radius: 15px;
    }

    &-portfolioArrow {
      width: 200px;
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      margin: 0 20px;
      width: 30px !important;
      height: 30px !important;
      border-radius: 50px;
      &::after {
        font-size: 15px !important;
      }
    }

    &-button-prev {
      margin: 0 20px;
      width: 30px !important;
      height: 30px !important;
      border-radius: 50px;

      &::after {
        font-size: 15px !important;
      }
    }
  }
}

@media (max-width: 730px) {
  .portfolio {
    &-box {
      gap: 35px;
      max-width: 730px;

      h1 {
        color: black;
        margin-bottom: -20px;
        font-size: 26px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 13px;
        margin-bottom: 10px;
      }
      h3 {
        color: black;
        font-size: 11px;
        margin-bottom: 7px;
      }

      &__slider {
        display: flex;
        align-items: center;
        gap: 40px;

        &-body {
          margin-right: 60px;
          max-width: 550px;
          ul {
            gap: 2px;
            li {
              font-size: 10px;
              margin-left: 12px;
            }
          }

          .body-bottomSlide {
            margin-top: 7px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 11px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
      }
    }
  }

  .blackout-portfolio {
    z-index: 50;
    transition: 0.4s;
    border-top-right-radius: 18px;
  }

  .bottom-slide-body {
    margin: 0 0 0 75px;
  }

  .button {
    &-sliderItem {
      margin-top: 6px;
      width: 220px !important;
      padding: 8px;
      font-size: 10px;
      cursor: pointer;
      transition: 0.5s;
    }

    &-imageItem {
      width: 75% !important;
      padding: 11px;
      font-size: 12px;
      position: absolute;
      top: 95px;
      left: 40px;
    }

    &-portfolio {
      font-size: 11px;
      padding: 9px;
    }
  }

  .image {
    &-slide {
      width: 350px;
    }

    &-portfolioArrow {
      width: 150px;
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      margin: 0 15px;
      width: 25px !important;
      height: 25px !important;
      border-radius: 50px;
      &::after {
        font-size: 13px !important;
      }
    }

    &-button-prev {
      margin: 0 15px;
      width: 25px !important;
      height: 25px !important;
      border-radius: 50px;

      &::after {
        font-size: 13px !important;
      }
    }
  }
}

@media (max-width: 640px) {
  .portfolio {
    &-box {
      max-width: 640px;
      h1 {
        color: black;
        margin-bottom: -3px;
        font-size: 22px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      h3 {
        color: black;
        font-size: 17px;
        margin-bottom: 7px;
      }

      &__slider {
        align-items: flex-start;
        gap: 19px;
        flex-direction: column;

        &-body {
          margin: 0 30px 0 30px;
          ul {
            gap: 3px;
            li {
              font-size: 14px;
              margin-left: 18px;
            }
          }

          .body-bottomSlide {
            margin-top: 10px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 17px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bottom-slide {
    flex-direction: column-reverse !important;
  }

  a {
    width: 85%;
    .button {
      &-portfolio {
        font-size: 14px;
        padding: 10px;
        width: 100%;
      }
    }
  }

  .button {
    &-sliderItem {
      margin-top: 10px;
      width: 100% !important;
      padding: 11px;
      font-size: 12px;
    }

    &-imageItem {
      width: 65% !important;
      padding: 12px;
      font-size: 14px;
      position: absolute;
      top: 175px;
      left: 105px;
    }
  }

  .image {
    &-slide {
      width: 100%;
      height: 100%;
    }

    &-portfolioArrow {
      display: none;
    }
  }

  .image-slide {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .blackout-portfolio {
    &:after {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      top: 35% !important;
      margin: -20px 10px;
      width: 35px !important;
      height: 35px !important;
      border-radius: 50px;
      &::after {
        font-size: 15px !important;
      }
    }

    &-button-prev {
      top: 35% !important;
      margin: -20px 10px;
      width: 35px !important;
      height: 35px !important;
      border-radius: 50px;

      &::after {
        font-size: 15px !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .button {
    &-imageItem {
      width: 65% !important;
      padding: 13px;
      font-size: 14px;
      position: absolute;
      top: 155px;
      left: 95px;
    }
  }
}

@media (max-width: 550px) {
  .button {
    &-imageItem {
      width: 70% !important;
      padding: 13px;
      font-size: 14px;
      position: absolute;
      top: 145px;
      left: 75px;
    }
  }
}

@media (max-width: 500px) {
  .button {
    &-imageItem {
      width: 70% !important;
      padding: 13px;
      font-size: 14px;
      position: absolute;
      top: 125px;
      left: 70px;
    }
  }


  .blackout-portfolio {
    z-index: 50;
    transition: 0.4s;
  }
}

@media (max-width: 450px) {
  .button {
    &-imageItem {
      width: 70% !important;
      padding: 13px;
      font-size: 14px;
      position: absolute;
      top: 115px;
      left: 65px;
    }
  }
}

@media (max-width: 421px) {
  .button {
    &-imageItem {
      width: 75% !important;
      padding: 11px;
      font-size: 13px;
      position: absolute;
      top: 105px;
      left: 48px;
    }
  }
}

@media (max-width: 401px) {
  .button {
    &-imageItem {
      width: 75% !important;
      padding: 11px;
      font-size: 13px;
      position: absolute;
      top: 100px;
      left: 48px;
    }
  }
}

@media (max-width: 390px) {
  .portfolio {
    &-box {
      gap: 30px;
      max-width: 390px;

      h1 {
        color: black;
        margin-bottom: -3px;
        font-size: 22px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 260px;
      }
      h3 {
        color: black;
        font-size: 16px;
        margin-bottom: 7px;
      }

      &__slider {
        align-items: flex-start;
        gap: 19px;
        flex-direction: column;

        &-body {
          margin: 0 30px 0 30px;
          ul {
            gap: 3px;
            li {
              font-size: 15px;
              margin-left: 17px;
            }
          }

          .body-bottomSlide {
            margin-top: 10px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 16px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bottom-slide {
    flex-direction: column-reverse !important;
  }

  .button {
    &-sliderItem {
      margin-top: 10px;
      width: 100% !important;
      padding: 11px;
      font-size: 12px;
    }

    &-imageItem {
      width: 75% !important;
      padding: 11px;
      font-size: 12px;
      position: absolute;
      top: 95px;
      left: 45px;
    }

    &-portfolio {
      font-size: 14px;
      padding: 10px;
      width: 85%;
    }
  }

  .image {
    &-slide {
      width: 100%;
      height: 100%;
    }

    &-portfolioArrow {
      display: none;
    }
  }

  .swiper {
    width: 100%;

    &-button-next {
      margin: 0 10px;
      width: 35px !important;
      height: 35px !important;
      border-radius: 50px;
      &::after {
        font-size: 15px !important;
      }
    }

    &-button-prev {
      margin: 0 10px;
      width: 35px !important;
      height: 35px !important;
      border-radius: 50px;

      &::after {
        font-size: 15px !important;
      }
    }
  }
}

@media (max-width: 370px) {
  .portfolio {
    &-box {
      gap: 20px;
      max-width: 370px;

      h1 {
        color: black;
        margin-bottom: -3px;
        font-size: 22px;
      }

      h2 {
        color: rgb(89, 103, 209);
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 260px;
      }
      h3 {
        color: black;
        font-size: 13px;
        margin-bottom: 7px;
      }

      &__slider {
        align-items: flex-start;
        gap: 19px;
        flex-direction: column;

        &-body {
          margin: 0 30px 0 30px;
          ul {
            gap: 2px;
            li {
              font-size: 13px;
              margin-left: 16px;
            }
          }

          .body-bottomSlide {
            margin-top: 10px;
            gap: 10px;

            span {
              color: #ea5d2f;
              font-size: 14px;
            }
          }
        }
      }

      &__bottom {
        gap: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .button {
    &-sliderItem {
      margin-top: 10px;
      width: 100% !important;
      padding: 11px;
      font-size: 12px;
    }

    &-portfolio {
      font-size: 14px;
      margin-top: 10px;
      padding: 10px;
      width: 90%;
    }
  }
}

@media (max-width: 351px) {
  .button {
    &-imageItem {
      width: 75% !important;
      padding: 9px;
      font-size: 13px;
      position: absolute;
      top: 85px;
      left: 41px;
    }
  }
}

@media (max-width: 311px) {
  .button {
    &-imageItem {
      width: 75% !important;
      padding: 9px;
      font-size: 13px;
      position: absolute;
      top: 75px;
      left: 37px;
    }
  }
}
