.questions {
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;

  &-box {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    width: 1920px;

    h1 {
      margin: 0px 0 40px 45px;
    }

    &__body {
      margin-top: -50px;
      margin-left: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 35px;

      &-items {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: white;
        position: relative;
        z-index: 2;
      }
    }
  }
  .image-questions {
    width: 656px;
    height: 775px;
    margin-top: -148px;
  }
  svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 1610px) {
  .questions {
    margin-top: 75px;
    &-box {
      &__body {
        &-items {
          gap: 13px;
        }
      }
    }

    .image-questions {
      width: auto;
      height: 650px;
      margin-top: -130px;
    }
  }
}

@media (max-width: 1375px) {
  .questions {
    margin-top: 70px;

    &-box {
      max-width: 1375px;
      width: 1375px;

      h1 {
        margin: 0 0 37px 35px;
        font-size: 36px;
      }

      &__body {
        margin-top: -50px;
        margin-left: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 35px;

        &-items {
          display: flex;
          flex-direction: column;
          background-color: white;
          position: relative;
          z-index: 2;
          gap: 18px;
        }
      }
    }

    .image-questions {
      width: 493px;
      height: 558px;
      margin-top: -114px;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }
}

@media (max-width: 1040px) {
  .questions {
    margin-top: 70px;

    &-box {
      max-width: 1040px;

      h1 {
        margin: 0 0 0 30px;
        font-size: 26px;
      }

      &__body {
        position: relative;
        margin-top: 20px;
        margin-left: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 35px;

        &-items {
          display: flex;
          flex-direction: column;
          background-color: rgba(255, 255, 255, 0.803);
          position: relative;
          z-index: 2;
        }
      }
    }

    .image-questions {
      position: absolute;
      top: 0;
      right: 0;
      width: 364px;
      height: 458px;
      margin-top: -136px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 885px) {
  .questions {
    margin-top: 70px;

    &-box {
      max-width: 1375px;
      width: 1375px;

      h1 {
        margin: 0 0 5px 35px;
        font-size: 25px;
      }
    }

    .image-questions {
      width: 332px;
      height: 403px;
      margin-top: -115px;
    }
  }
}

@media (max-width: 735px) {
  .questions {
    margin-top: 70px;

    &-box {
      max-width: 1375px;
      width: 1375px;

      &__body {
        &-items {
          gap: 6px;
        }
      }

      h1 {
        margin: 0px 0 -5px 35px;
        font-size: 24px;
        position: relative;
        z-index: 2;
      }
    }

    .image-questions {
      width: 316px;
      height: 398px;
      margin-top: -125px;
    }
  }
}

@media (max-width: 620px) {
  .questions {
    margin-top: 35px;

    &-box {
      max-width: 1375px;
      width: 1375px;

      &__body {
        &-items {
          gap: 6px;
        }
      }

      h1 {
        margin: 0px 0 -5px 35px;
        font-size: 23px;
        position: relative;
        z-index: 2;
      }
    }

    .image-questions {
      width: 256px;
      height: 322px;
      margin-top: -79px;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

@media (max-width: 530px) {
  .questions {
    overflow: hidden;

    &-box {
      max-width: 1375px;
      width: 1375px;

      &__body {
        &-items {
          gap: 10px;
          width: 100%;
        }
      }

      h1 {
        margin: 0px 0 -10px 22px;
        font-size: 22px;
        position: relative;
        z-index: 2;
      }
    }

    .image-questions {
      width: 221px;
      height: 266px;
      margin-top: -39px;
    }

    svg {
      width: 15px;
      height: 15px;
      margin-right: 80px;
    }
  }
}

@media (max-width: 450px) {
  .questions {
    overflow: hidden;

    &-box {
      max-width: 1375px;
      width: 1375px;

      &__body {
        margin-top: 10px;
        &-items {
          gap: 10px;
          width: 100%;
        }
      }

      h1 {
        margin: 0px 0 5px 25px;
        font-size: 21px;
      }
    }

    .image-questions {
      width: 240px;
      height: 283px;
      margin-top: -39px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
