.header {
  padding: 12px 10px 12px 10px;
  background-color: white;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 100;

  &-box {
    max-width: 1850px;
    width: 1850px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 21px;
      line-height: 140%;
    }

    &__nav {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 24px;
    }

    &__btn {
      max-width: 350px;
      width: 350px;
      max-height: 63px;
      height: 50px;
      margin-right: 20px !important;
      font-size: 22px !important;
      background-color: white !important;
      color: rgb(89, 103, 209) !important;
      border: 2px solid rgb(89, 103, 209) !important;
      text-transform: none !important;
      padding: 0 0 0 0 !important;
    }
  }

  .img-logoHeader {
    max-width: 270px;
  }
}

.burger {
  display: none;
  position: relative;
  border: none;
  background: transparent;
  width: 40px;
  height: 26px;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 7px;
    background: #5967d1;
  }

  &::before {
    top: 0;
    box-shadow: 0 11px 0 #5967d1;
    transition: box-shadow 0.3s 0.15s, top 0.3s 0.15s, transform 0.3s;
  }

  &::after {
    bottom: 0;
    transition: bottom 0.3s 0.15s, transform 0.3s;
  }
}

.activeMenu {
  z-index: 1500;
  &::before {
    top: 11px;
    transform: rotate(45deg);
    box-shadow: 0 6px 0 rgba(0, 0, 0, 0);
    transition: box-shadow 0.15s, top 0.3s, transform 0.3s 0.15s;
  }

  &::after {
    bottom: 11px;
    transform: rotate(-45deg);
    transition: bottom 0.3s, transform 0.3s 0.15s;
  }
}

@media (max-width: 1800px) {
  .header {
    height: 55px;
    position: fixed;
    &-box {
      padding: 0 20px;
      max-width: 1780px;

      &__btn {
        margin-right: 20px;
        max-width: 270px;
        height: 47px;
      }
    }
    .img-logoHeader {
      max-width: 235px;
    }
  }
}

@media (max-width: 1610px) {
  .header {
    padding: 10px 0;
    position: fixed;
    &-box {
      padding: 0 20px;
      max-width: 1580px;

      a {
        font-size: 15px;
        line-height: 140%;
      }

      &__nav {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20px !important;
      }

      &__btn {
        margin-right: 20px;
        max-width: 270px;
        max-height: 63px;
        height: 45px;
        font-size: 13px;
      }
    }
    .img-logoHeader {
      max-width: 215px;
    }
  }
}

@media (max-width: 1400px) {
  .header {
    height: 50px;

    &-box {
      padding: 0 15px;
      max-width: 1400px;

      &__nav {
        gap: 15px !important;
        margin-right: 4px;
      }

      &__btn {
        max-width: 200px;
        height: 41px;
        font-size: 17px !important;
      }
    }
    .img-logoHeader {
      max-width: 180px;
      margin-left: 20px;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    height: 45px;

    &-box {
      padding: 0 15px;
      max-width: 1400px;

      &__btn {
        max-width: 210px;
        height: 37px;
        font-size: 16px !important;
      }
    }
    .img-logoHeader {
      max-width: 160px;
    }
  }
}

@media (max-width: 1000px) {
  .header {
    &-box {
      position: relative;
      padding: 0px;
      max-width: 1000px;

      &__nav {
        margin-right: 0;
        width: 100%;
        height: 43px;
        position: absolute;
        top: 54px;
        justify-content: center;
        gap: 30px;
        background: white;
        border-bottom: 1px solid rgb(213, 207, 230);
        border-top: 1px solid rgb(213, 207, 230);
      }
    }
  }
}

@media (max-width: 750px) {
  .header {
    z-index: 5;
    &-box {
      height: 45px;
      max-width: 750px;
      width: 750px;
      &__nav {
        display: none;
      }

      &__btn {
        display: none;
      }
    }
  }

  .burger {
    display: flex;
    margin-right: 20px;
  }

  .activeMenu {
    &::after,
    &::before {
      background-color: white;
    }
  }
}

@media (max-width: 500px) {
  .header {
    &-box {
      max-width: 500px;
      width: 500px;
      justify-content: space-between;
    }
  }

  .img-logoHeader {
    max-width: 150px !important;
  }
}
