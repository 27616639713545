.whyWe {
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;

  &-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1920px;
    width: 1920px;

    h1 {
      margin: 0 0 20px 45px;
    }

    &__item {
      margin-bottom: 10px;
      background: rgb(241, 243, 255);
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: 25px 40px 40px 20px;

      &-title {
        color: rgb(89, 103, 209);
        height: 55px;
        font-size: 31px;
        font-weight: 700;
        line-height: 130%;
        padding-bottom: 5px;
        border-bottom: 1px solid rgb(89, 103, 209);
        margin-left: 25px;
      }

      p {
        margin-right: 20px;
        max-width: 950px;
        font-size: 21px;
        line-height: 130%;
      }
    }
  }
}

@media (max-width: 1560px) {
  .whyWe {
    margin-top: 85px;

    &-box {
      gap: 14px;
      max-width: 1560px;
      width: 1560px;

      h1 {
        margin: 0 0 20px 45px;
      }

      &__item {
        margin-bottom: 10px;
        padding: 25px 35px 35px 20px;

        &-title {
          font-size: 28px;
          line-height: 130%;
          padding-bottom: 0px;
          margin-left: 20px;
        }

        p {
          margin-right: 20px;
          max-width: 850px;
          font-size: 17px;
          line-height: 120%;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .whyWe {
    margin-top: 80px;

    &-box {
      gap: 6px;
      max-width: 1400px;

      h1 {
        margin: 0 0 20px 45px;
        font-size: 36px !important;
      }

      &__item {
        margin-bottom: 10px;
        padding: 25px 35px 35px 20px;

        &-title {
          font-size: 22px;
          height: 35px;
          line-height: 130%;
          padding-bottom: 0px;
          margin-left: 20px;
        }

        p {
          margin-right: 5px;
          max-width: 700px;
          font-size: 17px;
          line-height: 120%;
        }
      }
    }
  }
}

@media (max-width: 1190px) {
  .whyWe {
    margin-top: 70px;

    &-box {
      gap: 5px;
      max-width: 1190px;

      h1 {
        margin: 0px 0 22px 30px;
        font-size: 28px !important;
      }

      &__item {
        margin-bottom: 10px;
        padding: 25px 30px 35px 15px;

        &-title {
          font-size: 18px;
          height: 30px;
          line-height: 130%;
          padding-bottom: 0px;
          margin-left: 15px;
        }

        p {
          margin-right: 5px;
          max-width: 650px;
          font-size: 14px;
          line-height: 120%;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .whyWe {
    margin-top: 70px;

    &-box {
      gap: 5px;
      max-width: 980px;

      h1 {
        margin: 0px 10px 10px 30px;
        font-size: 25px !important;
      }

      &__item {
        margin-bottom: 10px;
        padding: 20px 15px 20px 15px;

        &-title {
          font-size: 17px;
          height: 29px;
          line-height: 130%;
          padding-bottom: 0px;
          margin-left: 15px;
        }

        p {
          margin-right: 0px;
          max-width: 480px;
          font-size: 12px;
          line-height: 110%;
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .whyWe {
    margin-top: 70px;

    &-box {
      gap: 5px;
      max-width: 780px;

      h1 {
        margin: 0px 10px 10px 30px;
        font-size: 25px !important;
      }

      &__item {
        margin-bottom: 10px;
        padding: 12px 15px 12px 15px;

        &-title {
          font-size: 15px;
          height: 25px;
          line-height: 130%;
          padding-bottom: 0px;
          margin-left: 15px;
        }

        p {
          margin-right: 0px;
          max-width: 400px;
          font-size: 12px;
          line-height: 120%;
        }
      }
    }
  }
}

@media (max-width: 670px) {
  .whyWe {
    margin-top: 50px;

    &-box {
      gap: 5px;
      max-width: 660px;

      h1 {
        margin: 0px 10px 10px 30px;
        font-size: 22px !important;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        padding: 15px;

        &-title {
          font-size: 16px;
          height: 25px;
          line-height: 130%;
          margin-left: 0px;
          text-align: center;
        }

        p {
          text-align: center;
          margin-top: 8px;
          margin-right: 0px;
          max-width: 400px;
          font-size: 14px;
          line-height: 110%;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .whyWe {
    margin-top: 40px;

    &-box {
      max-width: 430px;
      align-items: center;

      h1 {
        margin: 0 0 10px 0;
        text-align: center;
        font-size: 21px !important;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;
        padding: 15px;

        &-title {
          font-size: 13px;
          height: 20px;
        }

        p {
          font-size: 12px;
          line-height: 110%;
        }
      }
    }
  }
}
