.devProcess {
  margin: 0 auto;
  margin-top: 120px;
  display: flex;
  justify-content: center;
  width: 100%;

  &-box {
    overflow: hidden;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1920px;
    width: 1920px;
    position: relative;

    h1 {
      margin-left: 45px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}

.devProcessItems {
  display: flex;
  &-body {
    &__title {
      margin-left: 45px;
      color: rgb(89, 103, 209);
      font-size: 30px;
      font-weight: 700;
      line-height: 140%;
      cursor: pointer;

      &:hover {
        color: rgb(234, 93, 47);
      }
    }
    p {
      margin-left: 45px;
      font-size: 22px;
      line-height: 140%;
      max-width: 710px;
      display: none;
    }
  }

  .image-devProcess {
    width: 900px;
    height: auto;
    position: absolute;
    display: none;
  }

  &:nth-child(3) {
    .image-devProcess {
      transform: scale(-1, 1);
    }
  }

  .active-devProcess {
    display: flex;
    opacity: 1;
    pointer-events: all;
    transition: 0.4s;

    &__images {
      display: flex;
      opacity: 1;
      pointer-events: all;
      transition: 0.4s;
      position: absolute;
      right: 100px;
      top: 35px;
    }
  }
}

@media (max-width: 1790px) {
  .devProcess {
    &-box {
      padding-bottom: 50px;
      gap: 30px;
      max-width: 1790px;

      h1 {
        margin-left: 42px;
        font-size: 36px;
      }
    }
  }

  .devProcessItems {
    display: flex;
    &-body {
      &__title {
        margin-left: 45px;
        font-size: 28px;
        font-weight: 700;
        line-height: 140%;
      }
      p {
        margin-left: 45px;
        font-size: 19px;
        line-height: 130%;
        max-width: 500px !important;
      }
    }

    .image-devProcess {
      max-height: 540px;
      height: 510px;
    }

    .active-devProcess {
      &__images {
        right: 50px;
        top: 0;
      }
    }
  }
}

@media (max-width: 1510px) {
  .devProcess {
    margin-top: 134px;
    &-box {
      padding-bottom: 45px;
      gap: 25px;
      max-width: 1510px;

      h1 {
        margin-left: 45px;
        font-size: 36px;
      }

      &__body {
        gap: 10px;
      }
    }
  }

  .devProcessItems {
    &-body {
      &__title {
        margin-left: 45px;
        font-size: 27px !important;
        font-weight: 700;
        line-height: 140%;
      }
    }

    .image-devProcess {
      max-height: 560px;
      height: 470px;
    }

    .active-devProcess {
      &__images {
        right: -120px;
        top: 0;
      }
    }
  }
}

@media (max-width: 1370px) {
  .devProcess {
    margin-top: 125px;
    &-box {
      padding-bottom: 40px;
      gap: 25px;
      max-width: 1370px;

      h1 {
        margin-left: 40px;
        font-size: 33px;
      }
    }
  }

  .devProcessItems {
    &-body {
      &__title {
        margin-left: 40px;
        font-size: 21px !important;
        font-weight: 700;
        line-height: 140%;
      }
      p {
        margin-left: 40px;
        font-size: 15px;
        line-height: 130%;
        max-width: 425px !important;
      }
    }

    .image-devProcess {
      height: 442px;
      width: auto;
    }

    .active-devProcess {
      &__images {
        right: -160px;
        top: -30px;
      }
    }
  }
}

@media (max-width: 1290px) {
  .devProcess {
    margin-top: 105px;
    &-box {
      padding-bottom: 35px;
      gap: 20px;
      max-width: 1290px;

      &__body {
        gap: 8px;
      }
      h1 {
        margin-left: 40px;
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 1190px) {
  .devProcess {
    &-box {
      max-width: 1190px;

      &__body {
        gap: 7px;
      }
      h1 {
        margin-left: 35px;
        font-size: 28px;
      }
    }
  }

  .devProcessItems {
    &-body {
      &__title {
        margin-left: 35px;
        font-size: 21px !important;
        font-weight: 700;
        line-height: 140%;
      }
      p {
        margin-left: 35px;
        font-size: 16px;
        line-height: 125%;
        max-width: 420px !important;
      }
    }

    .image-devProcess {
      max-height: 450px;
      height: 435px;
    }

    .active-devProcess {
      &__images {
        right: -350px;
        top: -40px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .devProcess {
    &-box {
      max-width: 1000px;

      &__body {
        gap: 5px;
      }

      h1 {
        margin-left: 35px;
        font-size: 26px;
      }
    }
  }

  .devProcessItems {
    &-body {
      &__title {
        margin-left: 35px;
        font-size: 20px !important;
        font-weight: 700;
        line-height: 130%;
      }
      p {
        margin-left: 35px;
        font-size: 14px;
        line-height: 125%;
        max-width: 350px !important;
      }
    }

    .image-devProcess {
      width: 630px;
      height: auto;
    }

    .active-devProcess {
      &__images {
        right: -180px;
        top: -10px;
      }
    }
  }
}

@media (max-width: 870px) {
  .devProcess {
    margin-top: 60px;
    &-box {
      max-width: 870px;

      &__body {
        gap: 2px;
      }

      h1 {
        margin-left: 33px;
        font-size: 25px;
      }
    }
  }

  .devProcessItems {
    &-body {
      &__title {
        margin-left: 33px;
        font-size: 19px !important;
        font-weight: 700;
        line-height: 140%;
      }
      p {
        margin-left: 33px;
        font-size: 14px;
        line-height: 125%;
        max-width: 300px !important;
      }
    }

    .image-devProcess {
      height: auto;
      width: 530px;
    }

    .active-devProcess {
      &__images {
        right: -195px;
        top: 0px;
      }
    }
  }
}

@media (max-width: 685px) {
  .devProcess {
    margin-top: 50px;
    &-box {
      max-width: 685px;

      &__body {
        gap: 2px;
      }

      h1 {
        margin-left: 20px;
        font-size: 23px;
      }
    }
  }

  .devProcessItems {
    &-body {
      &__title {
        margin-left: 20px;
        font-size: 18px !important;
        font-weight: 700;
        line-height: 140%;
      }
      p {
        margin-left: 20px;
        font-size: 13px;
        line-height: 120%;
        max-width: 280px !important;
      }
    }

    .image-devProcess {
      height: auto;
      width: 510px;
    }

    .active-devProcess {
      &__images {
        right: -280px;
        top: 0px;
      }
    }
  }
}

@media (max-width: 530px) {
  .devProcess {
    margin-top: 60px;
    &-box {
      max-width: 685px;
      align-items: center;
      gap: 10px;

      &__body {
        gap: 9px;
      }

      h1 {
        margin-left: 20px;
        font-size: 23px;
      }
    }
  }

  .devProcessItems {
    flex-direction: column;
    align-items: center;
    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__title {
        margin-left: 0px;
        font-size: 19px !important;
        font-weight: 700;
        line-height: 140%;
        text-align: center;
      }
      p {
        margin-left: 0px;
        font-size: 15px;
        line-height: 125%;
        max-width: 80% !important;
        text-align: center;
      }
    }

    .image-devProcess {
      max-width: 70%;
      height: auto;
      margin: 10px 0 10px 0;
    }

    .active-devProcess {
      &__images {
        right: 0px;
        top: 0px;
        position: relative;
      }
    }
  }
}

@media (max-width: 390px) {
  .devProcess {
    margin-top: 60px;
    &-box {
      max-width: 390px;
      align-items: center;
      gap: 8px;

      &__body {
        gap: 7px;
      }

      h1 {
        margin-left: 20px;
        font-size: 22px;
      }
    }
  }

  .devProcessItems {
    &-body {
      &__title {
        font-size: 17px !important;
        font-weight: 700;
        line-height: 130%;
        text-align: center;
      }
      p {
        margin-left: 0px;
        font-size: 15px;
        line-height: 125%;
        max-width: 85% !important;
        text-align: center;
      }
    }

    .image-devProcess {
      max-width: 95%;
      height: auto;
      margin: 10px 0 10px 0;
    }

    .active-devProcess {
      &__images {
        right: 0px;
        top: 0px;
        position: relative;
      }
    }
  }
}
