.questionsItems {
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid rgba(156, 149, 174, 0.814);
  transition: 0.4s;

  &-title {
    font-size: 25px;
    max-width: 900px;
    font-weight: 600;
    line-height: 42px;
    margin-bottom: 10px;
    transition: 0.4s;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .activeArrow {
      path {
        fill: rgb(89, 103, 209);
      }
    }
  }

  &-text {
    max-width: 900px;
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    display: none;
    transition: 0.4s;
  }
}

.active-questions {
  display: flex;
  opacity: 1;
  pointer-events: all;
  transition: 0.4s;
}

@media (max-width: 1610px) {
  .questionsItems {
    &-title {
      font-size: 21px;
      max-width: 800px;
      line-height: 120%;
      margin-bottom: 8px;
    }

    &-text {
      max-width: 800px;
      font-size: 18px;
      line-height: 120%;
      transition: 0.4s;
    }
  }
}

@media (max-width: 1450px) {
  .questionsItems {
    &-title {
      font-size: 20px;
      max-width: 700px;
      line-height: 32px;
      margin-bottom: 10px;
    }

    &-text {
      max-width: 700px;
      font-size: 15px;
      line-height: 120%;
      transition: 0.4s;
    }
  }
}

@media (max-width: 1375px) {
  .questionsItems {
    padding: 0 10px 10px 10px;
    &-title {
      font-size: 19px;
      max-width: 600px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    &-text {
      max-width: 600px;
      font-size: 14px;
      line-height: 18px;
      transition: 0.4s;
    }
  }
}

@media (max-width: 1040px) {
  .questionsItems {
    padding: 0 10px 10px 10px;
    &-title {
      font-size: 15px;
      max-width: 500px;
      line-height: 120%;
      margin-bottom: 5px;
    }

    &-text {
      max-width: 500px;
      font-size: 12px;
      line-height: 120% !important;
      transition: 0.4s;
    }
  }
}

@media (max-width: 885px) {
  .questionsItems {
    padding: 0 8px 8px 8px;
    &-title {
      font-size: 13px;
      max-width: 400px;
      line-height: 130%;
      margin-bottom: 3px;
    }

    &-text {
      max-width: 400px;
      font-size: 11px;
    }
  }
}

@media (max-width: 735px) {
  .questionsItems {
    padding: 0 8px 8px 8px;
    &-title {
      font-size: 13px;
      max-width: 300px;
      line-height: 130%;
      margin-bottom: 3px;
    }

    &-text {
      max-width: 300px;
      font-size: 11px;
    }
  }
}

@media (max-width: 620px) {
  .questionsItems {
    padding: 0 8px 8px 8px;
    &-title {
      font-size: 11px;
      max-width: 250px;
      line-height: 15px;
      margin-bottom: 3px;
    }

    &-text {
      max-width: 250px;
      font-size: 9px;
      line-height: 15px;
    }
  }
}

@media (max-width: 530px) {
  .questionsItems {
    width: 100%;
    padding: 0 8px 8px 0;
    &-title {
      font-size: 13px;
      max-width: 80%;
      line-height: 15px;
      margin-bottom: 3px;
    }

    &-text {
      max-width: 60%;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

@media (max-width: 450px) {
  .questionsItems {
    width: 100%;
    padding: 0 8px 8px 0;
    &-title {
      font-size: 12px;
      max-width: 95%;
      line-height: 120%;
      margin-bottom: 3px;
    }

    &-text {
      max-width: 95%;
      font-size: 11px;
      line-height: 15px;
    }
  }
}
