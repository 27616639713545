.modalСonnection {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.496);
  position: fixed;
  z-index: 1120;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;

  &-content {
    padding: 40px 60px 40px 60px;
    width: 470px;
    background-color: white;
    border-radius: 26px;
    box-shadow: 0px 6px 12px 0px rgba(28, 30, 49, 0.1),
      0px 22px 22px 0px rgba(28, 30, 49, 0.09),
      0px 51px 30px 0px rgba(28, 30, 49, 0.05),
      0px 90px 36px 0px rgba(28, 30, 49, 0.01),
      0px 140px 39px 0px rgba(28, 30, 49, 0);
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      color: black;
      font-size: 27px !important;
      font-weight: 700;
      line-height: 130%;
      text-align: center;
      margin-bottom: 18px;
    }

    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0px;
    }

    .button-modal {
      width: 80%;
      font-size: 18px;
      padding: 12px;
    }

    .disable {
      background-color: rgba(120, 132, 221, 0.895);
      color: rgba(255, 255, 255, 0.877);

      &:hover {
        background-color: rgba(120, 132, 221, 0.895);
        color: rgba(255, 255, 255, 0.877);
      }
    }

    .image-exit {
      width: 40px;
      height: 40px;
      position: absolute;
      top: -135px;
      right: -115px;
      cursor: pointer;
    }

    .baseInput {
      width: 425px;
      height: 25px;
    }
  }
}

.activeModal {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 1350px) {
  .modalСonnection {
    &-content {
      padding: 40px 50px 40px 50px;
      width: 440px;
      border-radius: 24px;

      h1 {
        color: black;
        font-size: 23px !important;
        margin-bottom: 18px;
      }

      &__body {
        gap: 0px;
      }

      .button-modal {
        width: 92%;
        font-size: 18px;
        margin: 0;
        padding: 12px;
      }

      .image-exit {
        width: 40px;
        height: 40px;
        top: -117px;
        right: -116px;
      }

      .baseInput {
        width: 370px;
        height: 22px;
      }

      .body-checkbox h5 {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1150px) {
  .modalСonnection {
    &-content {
      padding: 35px 45px 36px 45px;
      width: 375px;
      border-radius: 24px;

      h1 {
        color: black;
        font-size: 21px !important;
        height: 120%;
        margin-bottom: 18px;
      }

      .button-modal {
        width: 92%;
        font-size: 16px;
        padding: 12px;
      }

      .image-exit {
        width: 40px;
        height: 40px;
        top: -107px;
        right: -100px;
      }

      .baseInput {
        width: 340px;
        height: 18px;
        padding: 12px 24px 13px 24px;
        margin-bottom: 7px;
      }

      .body-checkbox h5 {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 750px) {
  .modalСonnection {
    &-content {
      padding: 35px;
      width: 320px;
      border-radius: 24px;

      h1 {
        color: black;
        font-size: 17px !important;
        height: 120%;
        margin-bottom: 15px;
      }

      .button-modal {
        width: 92%;
        font-size: 14px;
        padding: 10px;
      }

      .image-exit {
        width: 30px;
        height: 30px;
        top: -102px;
        right: -80px;
      }

      .baseInput {
        width: 270px;
        height: 15px;
        padding: 10px 24px 11px 24px;
        margin-bottom: 4px;
      }

      .body-checkbox h5 {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 565px) {
  .modalСonnection {
    &-content {
      padding: 30px;
      width: 250px;
      border-radius: 18px;
      gap: 4px;

      h1 {
        color: black;
        font-size: 15px !important;
        height: 120%;
        margin-bottom: 10px;
      }

      &__body {
        gap: 4px;
      }

      .button-modal {
        width: 92%;
        font-size: 11px;
        padding: 8px;
      }

      .image-exit {
        width: 22px;
        height: 22px;
        top: -114px;
        right: -49px;
      }

      .baseInput {
        width: 200px;
        height: 15px;
        padding: 8px 20px 9px 20px;
        margin-bottom: 4px;
      }

      .body-checkbox h5 {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 380px) {
  .modalСonnection {
    &-content {
      padding: 25px;
      width: 270px;
      border-radius: 18px;
      gap: 4px;

      h1 {
        color: black;
        font-size: 15px !important;
        height: 120%;
        margin-bottom: 10px;
      }

      &__body {
        gap: 4px;
      }

      .button-modal {
        width: 92%;
        font-size: 11px;
        padding: 8px;
      }

      .image-exit {
        width: 26px;
        height: 30px;
        top: -120px;
        right: -29px;
      }

      .baseInput {
        width: 220px;
        height: 15px;
        padding: 8px 20px 9px 20px;
        margin-bottom: 4px;
      }

      .body-checkbox h5 {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 346px) {
  .modalСonnection {
    &-content {
      padding: 20px;
      width: 270px;
      border-radius: 18px;
      gap: 4px;

      h1 {
        color: black;
        font-size: 15px !important;
        height: 120%;
        margin-bottom: 10px;
      }

      &__body {
        gap: 2px;
      }

      .button-modal {
        width: 92%;
        font-size: 11px;
        padding: 8px;
      }

      .image-exit {
        width: 26px;
        height: 30px;
        top: -117px;
        right: -16px;
      }

      .baseInput {
        width: 220px;
        height: 15px;
        padding: 8px 20px 9px 20px;
        margin-bottom: 4px;
      }

      .body-checkbox h5 {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}
