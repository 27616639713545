.baseInput {
  border: 1px solid rgb(156, 149, 174);
  border-radius: 100px;
  display: flex;
  align-items: center;
  width: 500px;
  height: 34px;
  padding: 15px 25px 13px 25px;
  margin-bottom: 8px;

  input {
    border: none;
    height: 100%;
    width: 100%;
    background-color: transparent;
    outline: none;
    font-size: 16px;

    &::placeholder {
      color: rgb(156, 149, 174);
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }
  }
}

.focus {
  outline: none;
  &::placeholder {
    color: rgb(156, 149, 174);
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
  }
}

.focus.invalid {
  &::placeholder {
    color: rgb(157, 19, 19);
  }
}

.invalid {
  border: 1px solid rgb(157, 19, 19);

  input {
    &::placeholder {
      color: rgb(157, 19, 19);
    }
  }
}

@media (max-width: 1340px) {
  .baseInput {
    width: 390px;
    padding: 12px 20px 10px 20px;
    font-size: initial;

    input {
      &::placeholder {
        font-size: 16px;
        line-height: 100%;
      }
    }
  }
}

@media (max-width: 1050px) {
  .baseInput {
    width: 360px;
    height: 32px;
    padding: 12px 18px 10px 18px;
    margin-bottom: 8px;

    input {
      &::placeholder {
        font-size: 15px;
        line-height: 100%;
      }
    }
  }

  .focus {
    &::placeholder {
      font-size: 15px;
    }
  }
}

@media (max-width: 740px) {
  .baseInput {
    width: 300px;
    height: 28px;
    padding: 9px 18px 7px 18px;
    margin-bottom: 8px;

    input {
      &::placeholder {
        font-size: 13px;
        line-height: 100%;
      }
    }
  }

  .focus {
    &::placeholder {
      font-size: 13px;
    }
  }
}

@media (max-width: 550px) {
  .baseInput {
    width: 260px;
    height: 28px;
    padding: 5px 12px 5px 12px;
    margin-bottom: 6px;
    border-radius: 40px;

    input {
      height: 80%;
      &::placeholder {
        font-size: 12px;
        line-height: 100%;
      }
    }
  }

  .focus {
    &::placeholder {
      font-size: 12px;
    }
  }
}

@media (max-width: 465px) {
  .baseInput {
    width: 220px;
    height: 25px;
    padding: 4px 12px 4px 12px;
    margin-bottom: 5px;
    border-radius: 40px;

    input {
      height: 80%;
      &::placeholder {
        font-size: 11px;
        line-height: 100%;
      }
    }
  }

  .focus {
    &::placeholder {
      font-size: 11px;
    }
  }
}

@media (max-width: 390px) {
  .baseInput {
    width: 200px;
    height: 22px;
    padding: 4px 12px 4px 12px;
    margin-bottom: 5px;
    border-radius: 40px;

    input {
      height: 80%;
      &::placeholder {
        font-size: 11px;
        line-height: 100%;
      }
    }
  }

  .focus {
    &::placeholder {
      font-size: 11px;
    }
  }
}
