.formContact {
  overflow: hidden;
  margin: 0 auto;
  margin-top: 45px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100%;

  &-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0f112f;
    position: relative;
    height: 842px;
    max-width: 1920px;
    width: 1920px;
    z-index: 1;

    &__head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      gap: 75px;
      margin: 90px 0 50px 0;
      h1 {
        color: white;
        font-size: 42px !important;
        font-weight: 600;
        line-height: 140%;
        position: relative;
        z-index: 10;
      }
    }

    &__form {
      padding: 40px 60px 40px 60px;
      width: 580px;
      background-color: white;
      border-radius: 26px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 10;

      h2 {
        color: black;
        font-size: 34px;
        font-weight: 500;
        line-height: 140%;
        text-align: center;
        margin-bottom: 15px;
      }
      &-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &__network {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 400px;
      position: absolute;
      z-index: 10;
      left: 45px;
      top: 330px;

      h3 {
        color: rgb(255, 255, 255);
        font-size: 27px;
        font-weight: 500;
        line-height: 140%;
        position: relative;
        z-index: 10;
      }

      &-social {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
      }
    }

    .image {
      &-arrowHead {
        width: 490px;
        position: relative;
        z-index: 10;

        &:first-child {
          transform: rotate(180deg);
        }
      }

      &-network {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        &:nth-child(1) {
          width: 45px;
          height: 45px;
        }
        &:nth-child(2) {
          width: 30px;
          height: 30px;
        }
        &:nth-child(3) {
          width: 49px;
          height: 49px;
        }
      }

      &-bg {
        height: 100%;
        max-width: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }

    .button-form {
      width: 75%;
      font-size: 20px;
      position: relative;
      z-index: 10;
    }
  }
}

.disableForm {
  background-color: rgba(120, 132, 221, 0.895);
  color: rgba(255, 255, 255, 0.877);

  &:hover {
    background-color: rgba(120, 132, 221, 0.895);
    color: rgba(255, 255, 255, 0.877);
  }
}

@media (max-width: 1720px) {
  .formContact {
    &-box {
      max-width: 1720px;

      &__head {
        margin: 120px 0 50px 0;
        h1 {
          color: white;
          font-size: 40px;
          line-height: 120%;
        }
      }

      &__form {
        padding: 40px 60px 40px 60px;
        width: 580px;
        background-color: white;
        border-radius: 26px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 10;

        h2 {
          color: black;
          font-size: 34px;
          font-weight: 500;
          line-height: 140%;
          text-align: center;
          margin-bottom: 15px;
        }
        &-body {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      &__network {
        h3 {
          color: rgb(255, 255, 255);
          font-size: 25px;
          line-height: 130%;
        }

        &-social {
          gap: 10px;
        }
      }

      .image {
        &-arrowHead {
          width: 453px;
          position: relative;
          z-index: 10;

          &:first-child {
            transform: rotate(180deg);
          }
        }

        &-network {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          &:nth-child(1) {
            position: relative;
            width: 45px;
            height: 45px;
          }
          &:nth-child(2) {
            width: 30px;
            height: 30px;
          }
          &:nth-child(3) {
            width: 49px;
            height: 49px;
          }
        }

        &-bg {
          height: 892px;
          max-width: 1720px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
      }

      .button {
        &-form {
          width: 75%;
          font-size: 20px;
          position: relative;
          z-index: 10;
        }
      }
    }
  }
}

@media (max-width: 1676px) {
  .formContact {
    &-box {
      height: 770px;
      max-width: 1676px;

      &__head {
        display: flex;
        flex-direction: row;
        gap: 75px;
        margin: 80px 0 50px 0;
        h1 {
          font-size: 39px !important;
        }
      }

      &__form {
        padding: 40px 60px 40px 60px;
        width: 530px;

        h2 {
          font-size: 28px;
          line-height: 130%;
        }
      }

      &__network {
        gap: 10px;
        left: 45px;
        top: 320px;
        width: 310px;

        h3 {
          font-size: 21px;
        }

        &-social {
          gap: 10px;
        }
      }

      .image {
        &-arrowHead {
          width: 350px;
        }

        &-bg {
          height: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1460px) {
  .formContact {
    overflow: hidden;
    &-box {
      height: 740px;
      max-width: 1460px;

      &__head {
        h1 {
          font-size: 35px !important;
        }
      }

      &__form {
        padding: 30px 40px 30px 40px;
        width: 525px;

        h2 {
          font-size: 28px;
        }
      }
    }
  }
}

@media (max-width: 1340px) {
  .formContact {
    &-box {
      height: 600px;
      max-width: 1340px;

      &__head {
        margin: 43px 0 40px 0;
        h1 {
          font-size: 23px !important;
          line-height: 130%;
          text-align: center;
        }
      }

      &__form {
        padding: 30px;
        width: 430px;

        h2 {
          font-size: 21px;
        }
      }

      &__network {
        gap: 8px;
        width: 290px;
        left: 40px;
        top: 410px;

        h3 {
          font-size: 16px;
        }
      }

      &-social {
        justify-content: flex-start;
        align-items: center;
      }
    }

    .button {
      &-form {
        width: 85%;
        padding: 13px;
        font-size: 17px;
        position: relative;
        z-index: 10;
      }
    }

    .image {
      &-bg {
        height: 100%;
        max-width: 100%;
      }

      &-arrowHead {
        width: 200px;
      }
    }
  }
}

@media (max-width: 1050px) {
  .formContact {
    &-box {
      gap: 30px;
      z-index: 1;
      height: 620px;
      max-width: 1050px;

      &__head {
        margin: 43px 0 10px 0;
        justify-content: center;
        h1 {
          font-size: 22px !important;
          line-height: 130%;
          text-align: center;
        }
      }

      &__form {
        padding: 30px;
        width: 400px;

        h2 {
          font-size: 19px;
        }
      }

      &__network {
        position: static;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 22px;
        width: auto;

        h3 {
          font-size: 15px;
        }
      }
    }

    .image {
      &-network {
        gap: 8px;

        &:nth-child(1) {
          position: relative;
          width: 33px;
          height: 33px;
        }
        &:nth-child(2) {
          width: 18px;
          height: 18px;
        }
        &:nth-child(3) {
          width: 37px;
          height: 47px;
        }
      }

      &-arrowHead {
        display: none;
      }
    }
  }
}

@media (max-width: 710px) {
  .formContact {
    &-box {
      height: 624px;
      max-width: 700px;

      &__head {
        margin: 43px 0 10px 0;
        h1 {
          font-size: 18px !important;
          line-height: 130%;
          text-align: center;
        }
      }

      &__form {
        padding: 25px;
        width: 340px;

        h2 {
          font-size: 16px;
        }
      }

      &__network {
        gap: 5px;
        display: flex;
        flex-direction: column;

        h3 {
          font-size: 14px;
          line-height: 120%;
        }
      }

      .button {
        &-form {
          width: 75%;
          padding: 10px;
          font-size: 13px;
          position: relative;
          z-index: 10;
        }
      }

      .image {
        &-network {
          gap: 5px;

          &:nth-child(1) {
            position: relative;
            width: 30px;
            height: 25px;
          }
          &:nth-child(2) {
            width: 21px;
            height: 14px;
          }
          &:nth-child(3) {
            width: 35px;
            height: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .formContact {
    &-box {
      height: 520px;
      max-width: 540px;
      gap: 25px;

      &__head {
        margin: 33px 0 0px 0;
        h1 {
          font-size: 20px !important;
          line-height: 130%;
          text-align: center;
        }
      }

      &__form {
        padding: 25px;
        width: 340px;

        h2 {
          font-size: 14px;
        }
      }

      &__network {
        h3 {
          font-size: 12px;
          line-height: 120%;
        }
      }

      .button {
        &-form {
          width: 75%;
          padding: 9px;
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .formContact {
    &-box {
      height: 500px;
      max-width: 450px;

      &__head {
        margin: 33px 0 0px 0;
        h1 {
          font-size: 18px !important;
          line-height: 130%;
          text-align: center;
        }
      }

      &__form {
        padding: 25px;
        width: 240px;
        border-radius: 18px;

        h2 {
          font-size: 14px;
        }
      }

      &__network {
        h3 {
          font-size: 12px;
          line-height: 110%;
        }
      }

      .button {
        &-form {
          width: 85%;
          font-size: 12px;
          padding: 8px;
        }
      }
    }
  }
}

@media (max-width: 365px) {
  .formContact {
    &-box {
      height: 460px;
      max-width: 360px;
      gap: 20px;

      &__head {
        margin: 30px 0 5px 0;
        h1 {
          font-size: 15px;
          line-height: 20px;
        }
      }

      &__form {
        padding: 20px 15px 20px 15px;
        width: 230px;

        h2 {
          font-size: 13px;
          line-height: 14px;
        }
      }

      &__network {
        h3 {
          font-size: 10px;
          line-height: 110%;
        }
      }

      .button {
        &-form {
          width: 75%;
          font-size: 11px;
          padding: 8px;
        }
      }
    }
  }
}
