.calc {
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  width: 100%;

  &-box {
    display: flex;
    flex-direction: column;
    background: #0f112f;
    position: relative;
    height: 770px;
    max-width: 1920px;
    width: 1920px;
    z-index: 0;

    &__head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;

      &-title {
        margin: 45px 0 0 45px;
        color: white;
        font-size: 40px;
        font-weight: 600;
        line-height: 140%;
        text-transform: uppercase;
        position: relative;
        z-index: 2;
      }

      &-lineArrow {
        width: 845px;
        position: relative;
        z-index: 2;
      }
    }

    &__body {
      display: none;
      margin-top: 50px;
      position: relative;
      z-index: 2;

      &-head {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        margin-top: -20px;

        h2 {
          margin-left: 45px;
          color: rgb(255, 195, 124);
          font-size: 39px;
          font-weight: 700;
          line-height: 140%;
          position: relative;
          z-index: 2;
        }

        h3 {
          color: rgb(255, 195, 124);
          font-size: 29px;
          font-weight: 400;
          line-height: 140%;
          position: relative;
          z-index: 2;
        }
      }
      &-container {
        margin: 15px 0 0 45px;
        display: flex;
        flex-direction: row;
        gap: 100px;

        .container {
          &-checkbox {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }

          &-finaly {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &__price {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 260px;
            }
          }
        }
      }

      &-containerFunc {
        display: flex;
        gap: 100px;
        flex-direction: row;
        margin-left: 45px;
        margin-top: 15px;

        .container {
          &-block {
            display: flex;
            flex-direction: row;
            gap: 40px;
          }
        }
        .checkboxSquare-box {
          margin-bottom: 15px;
        }
      }

      &-bottom {
        margin: 70px 0 0 45px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
      }

      &-finalyBottom {
        margin: -70px 0 0 45px;
      }

      span {
        color: rgb(255, 255, 255);
        font-size: 26px;
        font-weight: 500;
        line-height: 140%;
        position: relative;
        z-index: 2;
      }

      p {
        color: rgb(255, 255, 255);
        font-size: 31px;
        font-weight: 800;
        line-height: 140%;
        position: relative;
        z-index: 2;
      }
    }
  }

  .bottom-calc {
    &__btn {
      background-color: white;
      color: rgb(89, 103, 209);
      box-shadow: 0px 1px 3px 0px rgba(144, 153, 225, 0.2),
        0px 6px 6px 0px rgba(144, 153, 225, 0.17),
        0px 13px 8px 0px rgba(144, 153, 225, 0.1),
        0px 22px 9px 0px rgba(144, 153, 225, 0.03),
        0px 35px 10px 0px rgba(144, 153, 225, 0);
      position: relative;
      z-index: 3;
      width: 404px;
    }
  }

  .image {
    &-phone {
      width: 540px;
      height: auto;
      margin-top: -125px;
      margin-left: 300px;
      position: relative;
      z-index: 2;
    }

    &-BgCulc {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
}

.disableCalc {
  background-color: rgba(255, 255, 255, 0.877) !important;
  color: rgba(120, 132, 221, 0.895) !important;

  &:hover {
    background-color: rgba(255, 255, 255, 0.877) !important;
    color: rgba(120, 132, 221, 0.895) !important;
  }
}

.active-calcTab {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1750px) {
  .calc {
    margin-top: 60px;

    &-box {
      height: 700px;
      max-width: 1750px;

      &__head {
        gap: 6px;

        &-title {
          margin: 45px 0 0 45px;
          color: white;
          font-size: 36px;
          line-height: 140%;
        }

        &-lineArrow {
          width: 750px;
        }
      }

      &__body {
        margin-top: 40px;

        &-head {
          gap: 20px;

          h2 {
            margin-left: 45px;
            color: rgb(255, 195, 124);
            font-size: 38px;
            line-height: 140%;
          }

          h3 {
            color: rgb(255, 195, 124);
            font-size: 28px;
            line-height: 140%;
          }
        }
        &-container {
          margin: 15px 0 0 45px;
          gap: 100px;

          .container {
            &-checkbox {
              gap: 20px;
            }

            &-finaly {
              gap: 11px;

              &__price {
                gap: 260px;
              }
            }
          }
        }

        &-containerFunc {
          gap: 90px;
          margin-left: 45px;
          margin-top: 15px;

          .container {
            &-block {
              gap: 40px;
            }
          }
          .checkboxSquare-box {
            margin-bottom: 15px;
          }
        }

        &-bottom {
          margin: 50px 0 0 45px;
          gap: 20px;
        }

        &-finalyBottom {
          margin: -90px 0 0 45px;
        }

        span {
          color: rgb(255, 255, 255);
          font-size: 24px;
          line-height: 140%;
        }

        p {
          color: rgb(255, 255, 255);
          font-size: 28px;
          line-height: 140%;
        }
      }
    }

    .bottom-calc {
      &__btn {
        width: 404px;
      }
    }

    .image {
      &-phone {
        width: 520px;
        height: auto;
        margin-top: -111px;
        margin-left: 190px;
      }
    }
  }
}

@media (max-width: 1620px) {
  .calc {
    width: 100%;

    &-box {
      height: 630px;
      position: relative;
      max-width: 1620px;

      &__head {
        &-title {
          margin-top: 30px;
          font-size: 30px;
        }

        &__body {
          &__head {
            h2 {
              margin-left: 45px;
              color: rgb(255, 195, 124);
              font-size: 34px !important;
              line-height: 130%;
            }

            h3 {
              color: rgb(255, 195, 124);
              font-size: 24px !important;
              line-height: 130%;
            }
          }
        }

        &-lineArrow {
          margin-top: 20px;
          width: 635px;
          position: relative;
          z-index: 2;
        }
      }

      .bottom-calc {
        &__btn {
          width: 404px;
        }
      }
    }
  }
}

@media (max-width: 1550px) {
  .calc {
    margin-top: 60px;

    &-box {
      height: 545px;
      max-width: 1550px;

      &__head {
        gap: 6px;

        &-title {
          font-size: 26px;
        }

        &-lineArrow {
          margin-top: 20px;
          width: 500px;
          position: relative;
          z-index: 2;
        }
      }

      &__body {
        margin-top: 50px;
        &-head {
          gap: 20px;

          h2 {
            margin-left: 45px;
            color: rgb(255, 195, 124);
            font-size: 32px;
          }

          h3 {
            color: rgb(255, 195, 124);
            font-size: 25px;
          }
        }
        &-container {
          margin: 15px 0 0 45px;
          gap: 70px;

          .container-checkbox {
            gap: 12px;
          }
        }

        &-bottom {
          margin: 70px 0 0 45px;
          display: flex;
          flex-direction: row;
          gap: 20px;
          align-items: center;
        }

        &-finalyBottom {
          margin: -35px 0 0 45px;

          .bottom-calc__btn {
            font-size: 18px;
            padding: 12px;
          }
        }

        span {
          font-size: 23px;
          line-height: 120%;
        }

        p {
          font-size: 25px;
          line-height: 120%;
        }
      }

      .image {
        &-phone {
          width: 405px;
          height: auto;
          margin-top: -96px;
          margin-left: 125px;
        }
      }
    }
    .bottom-calc {
      position: relative;
      z-index: 3;

      &__btn {
        font-size: 18px;
        padding: 12px;
        width: 325px;
      }
    }
  }
}

@media (max-width: 1285px) {
  .calc {
    &-box {
      height: 530px;
      &__head {
        &-title {
          font-size: 25px;
        }

        &-lineArrow {
          width: 400px;
        }
      }
      &__body {
        &-container {
          gap: 50px;
        }

        &-finalyBottom {
          margin: -55px 0 0 45px;
        }

        &-containerFunc {
          .container {
            &-block {
              display: flex;
              flex-direction: row;
              gap: 40px;
            }
          }
        }
      }
      span {
        font-size: 20px;
        line-height: 120%;
      }

      p {
        font-size: 22px;
        line-height: 120%;
      }
    }
    .image {
      &-phone {
        width: 400px;
        margin-top: -100px;
        margin-left: 60px;
      }
    }
  }
}

@media (max-width: 1170px) {
  .calc {
    &-box {
      height: 475px;
      &__head {
        display: flex;
        flex-direction: row;
        gap: 6px;

        &-title {
          font-size: 23px;
        }

        &-lineArrow {
          display: none;
        }
      }

      &__body {
        margin-top: 40px;
        &-head {
          h2,
          h3 {
            font-size: 22px;
          }
          margin-bottom: 3px;
        }

        &-container {
          gap: 20px;

          .container-finaly {
            gap: 10px;
          }
        }

        &-containerFunc {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .container {
            &-block {
              display: flex;
              flex-direction: row;
              gap: 50px;
            }
          }

          .checkboxSquare-box {
            margin-bottom: 5px;
          }
        }

        &-bottom {
          margin-top: 30px;
        }

        &-finalyBottom {
          margin-top: 30px;
        }
      }

      span {
        font-size: 20px;
        line-height: 120%;
      }

      p {
        font-size: 21px;
        line-height: 120%;
      }
    }

    .image {
      &-phone {
        display: none;
      }
    }
  }
}

@media (max-width: 810px) {
  .calc {
    &-box {
      height: 475px;
      &__head {
        &-title {
          margin: 25px 0 0 25px;
          font-size: 20px;
        }

        &-lineArrow {
          display: none;
        }
      }

      &__body {
        &-head {
          gap: 10px;

          h2 {
            margin-left: 25px;
          }

          h2,
          h3 {
            font-size: 19px;
          }
        }

        &-container {
          flex-direction: column;
          margin-left: 25px;

          .container-finaly {
            width: 95%;
            p {
              font-size: 18px;
            }
            span {
              font-size: 16px;
            }
            &__price {
              gap: 0;
            }
          }
        }

        &-containerFunc {
          margin-left: 25px;
        }

        &-finalyBottom {
          margin-top: 25px;
          margin-left: 25px;
        }

        &-bottom {
          .bottom-calc__btn {
            margin-left: -20px;
          }
        }
      }
    }

    .image {
      &-phone {
        display: none;
      }
    }

    .baseButton {
      width: 335px;
      font-size: 17px;
    }
  }
}

@media (max-width: 630px) {
  .calc {
    margin-top: 10px;
    &-box {
      height: 505px;
      &__head {
        &-title {
          font-size: 21px;
        }
      }

      &__body {
        margin-top: 30px;
        &-head {
          gap: 10px;

          h3,
          h2 {
            font-size: 18px;
          }
        }

        &-container {
          gap: 10px;
        }

        &-finalyBottom {
          margin-top: 35px;
        }
      }
    }
  }
}

@media (max-width: 490px) {
  .calc {
    &-box {
      width: 100%;
      height: 580px;
      &__head {
        &-title {
          font-size: 18px;
        }
      }

      &__body {
        margin-top: 30px;
        &-head {
          margin-left: 25px;
          margin-bottom: 0;
          gap: 10px;
          flex-wrap: wrap;
          h2 {
            margin: 0;
          }

          h3,
          h2 {
            font-size: 17px;
          }
        }

        &-container {
          .container {
            &-checkbox {
              gap: 5px;
            }

            &-finaly {
              span {
                font-size: 15px;
              }

              p {
                font-size: 17px;
              }
            }
          }
        }

        &-containerFunc {
          gap: 5px;

          .container {
            &-block {
              flex-direction: column;
              gap: 0;
            }
          }
        }

        &-bottom {
          margin-top: 15px;
        }

        &-finalyBottom {
          margin-top: 35px;
        }
      }

      span {
        font-size: 12px;
      }
    }

    .baseButton {
      width: 85%;
      font-size: 14px;
      padding: 8px;
    }
  }
}

@media (max-width: 375px) {
  .calc {
    &-box {
      width: 100%;
      height: 580px;
      &__head {
        &-title {
          font-size: 15px;
        }
      }

      &__body {
        margin-top: 30px;
        &-head {
          margin-left: 25px;
          margin-bottom: 0;
          gap: 10px;
          h3,
          h2 {
            font-size: 15px;
          }
        }

        &-container {
          .container {
            &-checkbox {
              gap: 5px;
            }

            &-finaly {
              span {
                font-size: 14px;
              }

              p {
                font-size: 16px;
              }
            }
          }
        }

        &-containerFunc {
          gap: 5px;

          .container {
            &-block {
              flex-direction: column;
              gap: 0;
            }
          }
        }

        &-bottom {
          margin-top: 15px;
        }

        &-finalyBottom {
          margin-top: 35px;
        }
      }

      span {
        font-size: 12px;
      }
    }

    .baseButton {
      width: 85%;
      font-size: 14px;
      padding: 8px;
    }
  }
}
