.dropdown {
  transition: 0.4s;
  height: 84px;
  display: flex;
  padding: 10px;

  &-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    transition: 0.4s;
    position: relative;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }

  p {
    font-size: 21px;
    line-height: 140%;
    cursor: pointer;
  }

  &-content {
    margin: 10px 0 0 -10px;
    opacity: 0;
    top: 97px;
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    gap: 6px;
    width: 365px;
    padding: 20px;
    background-color: rgb(241, 243, 255);
    pointer-events: none;
    transition: 0.4s;
    position: absolute;
  }
}

.activeDd {
  background-color: rgb(241, 243, 255);

  &-content {
    opacity: 1;
    pointer-events: all;
    transition: 0.4s;
    z-index: 99;
  }
}

@media (max-width: 1795px) {
  .dropdown {
    height: 60px;
    display: flex;
    padding: 10px;

    &-content {
      margin: 10px 0 0 -10px;
      top: 72px;
      margin-top: 6px;
      gap: 6px;
      width: 365px;
      padding: 20px;
    }
  }
}

@media (max-width: 1610px) {
  .dropdown {
    height: 55px;
    transition: 0.4s;
    display: flex;

    &-head {
      img {
        width: 22px;
        height: 22px;
      }
    }

    &-content {
      top: 69px;
    }

    p {
      font-size: 15px;
      line-height: 140%;
      cursor: pointer;
    }
  }
}

@media (max-width: 1400px) {
  .dropdown {
    height: 50px;
    &-head {
      img {
        width: 16px;
        height: 16px;
      }
    }

    p {
      font-size: 15px;
      line-height: 140%;
      cursor: pointer;
    }

    &-content {
      top: 64px;
      margin-left: -8px;
    }
  }
}

@media (max-width: 1150px) {
  .dropdown {
    transition: 0.4s;
    height: 52px;
    display: flex;
    padding: 10px;

    &-content {
      margin: 10px 0 0 -10px;
      top: 54px;
      width: 365px;
      padding: 20px;
    }
  }
}

@media (max-width: 1000px) {
  .dropdown {
    height: 24px;

    &-content {
      top: 34px;
      width: 230px;
    }
  }
}

@media (max-width: 750px) {
  .dropdown {
    flex-direction: column;
    padding: 0px;
    transition: 0.4s;
    height: 18px;

    &-head {
      img {
        width: 18px;
        height: 18px;
      }
    }

    p {
      margin-left: 45px;
      color: white;
      line-height: 140%;
      font-size: 28px !important;
      cursor: pointer;
    }

    &-content {
      margin: 10px 0 0 -10px;
      top: 85px;
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      gap: 6px;
      padding: 20px;
      background-color: transparent;
      pointer-events: none;
      transition: 0.4s;
      position: relative;
      z-index: 1550;
    }
  }

  .activeDd {
    background-color: transparent;
  }
}

@media (max-width: 320px) {
  .dropdown {
    p {
      margin-left: 30px !important;
      color: white;
      line-height: 140%;
      font-size: 24px !important;
      cursor: pointer;
    }
  }
}
