.fixed {
  overflow: hidden;
}

.base {
  &-text {
    font-weight: 400;
    color: rgb(10, 12, 19);
  }

  &-title {
    color: rgb(89, 103, 209);
    font-size: 40px;
    font-weight: 400;
    line-height: 140%;

    span {
      color: #5967D1;
    }
  }
}

.error-text {
  margin-top: -10px;
  margin-bottom: 8px;
  color: rgb(157, 19, 19);
  font-size: 12px;
}

.body {
  &-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &__head {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
  }

  &-checkbox {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 25px;

    h5 {
      color: rgb(89, 89, 98);
      font-size: 15px;
      font-weight: 400;
      line-height: 140%;
    }
    input {
      width: 23px;
    }
  }
}

.nav {
  &-text {
    color: rgb(89, 89, 98);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }
}

.activeArrow {
  transition: 0.4s;
  transform: rotate(180deg);
}

@media (max-width: 1340px) {
  .error-text {
    margin-top: -5px;
    margin-bottom: 8px;
    font-size: 12px;
  }

  .body {
    &-input {
      gap: 2px;
      &__head {
        p {
          font-size: 14px;
        }
        gap: 5px;
      }
    }

    &-checkbox {
      margin-bottom: 20px;
      align-items: center;

      h5 {
        font-size: 12px;
        line-height: 120%;
      }
    }
  }

  .nav {
    &-text {
      font-size: 11px;
      line-height: 120%;
    }
  }
}

@media (max-width: 750px) {
  .body {
    &-input {
      gap: 1px;
      &__head {
        p {
          font-size: 12px;
        }
        gap: 5px;
      }
    }

    &-checkbox {
      gap: 2px;
      margin-bottom: 18px;

      h5 {
        font-size: 10px;
        line-height: 120%;
      }
    }
  }

  .error-text {
    margin-top: -2px;
    margin-bottom: 8px;
    font-size: 11px;
  }

  .nav {
    &-text {
      font-size: 11px;
      line-height: 120%;
    }
  }
}

@media (max-width: 565px) {
  .body {
    &-input {
      gap: 0px;
      &__head {
        p {
          font-size: 10px;
          height: 90%;
        }
        gap: 3px;
      }
    }
  }

  .nav {
    &-text {
      font-size: 10px;
      line-height: 120%;
    }
  }
}

@media (max-width: 450px) {
  .body {
    &-checkbox {
      gap: 2px;
      margin-bottom: 14px;

      h5 {
        font-size: 8px;
        line-height: 110%;
      }
    }
  }

  .nav {
    &-text {
      text-align: center;
      font-size: 10px;
      line-height: 120%;
    }
  }
}

@media (max-width: 365px) {
  .body {
    &-input {
      gap: 1px;
      &__head {
        p {
          font-size: 10px;
          line-height: 14px;
        }
        span {
          font-size: 10px;
          line-height: 14px;
        }
        gap: 1px;
      }
    }

    &-checkbox {
      margin-bottom: 20px;
      input {
        width: 18px;
      }
    }
  }
}
