.preview {
  margin: 0 auto;
  margin-top: 74px;
  display: flex;
  justify-content: center;
  width: 100%;

  &-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 0;
    max-width: 1920px;
    width: 1920px;
    height: 1000px;
    justify-content: space-between;

    &__app {
      display: flex;
      flex-direction: column;
      &-item {
        .app-item__head {
          margin: 175px 0px 40px 45px;
          display: flex;
          flex-direction: column;
          max-width: 750px;
        }

        h1 {
          font-size: 35px;
          font-weight: 600;
          line-height: 135%;
        }

        .blackTitle {
          color: black;
        }
      }

      &-body {
        background: rgba(13, 17, 39, 0.291);
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        width: 1050px;
        right: 0;
        bottom: 75px;
        z-index: 5;
        row-gap: 8px;

        p {
          color: white;
          font-size: 24px;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0%;
        }

        span {
          color: #ea5d2f;
          font-size: 24px;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0%;
          text-transform: uppercase;
        }
      }
    }

    &__exp {
      width: 1050px;
      height: 956px;
      background: #0f112f;
      position: relative;
    }
  }
}

.image {
  &-bgApp {
    height: 833px;
    width: 871px;
    top: 124px;
    position: absolute;
    z-index: 1;
  }
  &-bgPhone {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
  }

  &-phones {
    margin-top: 50px;
    width: 869px;
    position: absolute;
    top: 0;
    left: 96px;
    z-index: 2;
  }
}

.button-preview {
  margin: 0 0 0 45px;
  width: 50%;
  cursor: pointer;
  position: relative;
  z-index: 50;
}

@media (max-width: 1846px) {
  .preview {
    &-box {
      &__app {
        &-body {
          width: 1018px;
        }
      }
    }
  }
}

@media (max-width: 1790px) {
  .preview {
    &-box {
      &__app {
        &-body {
          width: 988px;
        }
      }
    }
  }
}

@media (max-width: 1720px) {
  .preview {
    &-box {
      max-width: 1720px;
      height: 900px;
      justify-content: space-between;

      &__app {
        &-item {
          .app-item__head {
            margin: 180px 0px 35px 45px;
            max-width: 750px;
          }

          h1 {
            font-size: 32px;
            line-height: 125%;
          }
        }

        &-body {
          background: rgba(13, 17, 39, 0.291);
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px 0;
          width: 966px;
          right: 0;
          bottom: 85px;
          z-index: 5;

          p {
            color: white;
            font-size: 24px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0%;
            text-transform: uppercase;
          }

          span {
            color: #ea5d2f;
            font-size: 24px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0%;
            text-transform: uppercase;
          }
        }
      }

      &__exp {
        height: 900px;
        width: 1050px;
      }
    }
  }

  .image {
    &-bgApp {
      top: 162px;
      height: 82%;
      width: 743px;
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
    }
    &-bgPhone {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
    }

    &-phones {
      width: 840px;
      position: absolute;
      top: -10px;
      left: 70px;
      z-index: 2;
    }
  }
}

@media (max-width: 1700px) {
  .preview {
    &-box {
      &__app {
        &-body {
          width: 950px;
        }
      }
    }
  }
}

@media (max-width: 1660px) {
  .preview {
    margin-top: 80px;
    &-box {
      max-width: 1660px;
      height: 775px;
      justify-content: space-between;

      &__app {
        &-body {
          width: 861px;
        }
      }

      .app-item__head {
        margin: 150px 0px 30px 45px;

        h1 {
          font-size: 30px;
          line-height: 125%;
        }
      }

      &__exp {
        height: 775px;
        width: 900px;
      }
    }
  }

  .image {
    &-bgApp {
      height: 79%;
      width: 783px;
      top: 164px;
    }

    &-phones {
      width: 680px;
      top: -34px;
      left: 115px;
    }
  }

  .button-preview {
    width: 60%;
  }
}

@media (max-width: 1610px) {
  .preview {
    &-box {
      &__app {
        &-body {
          width: 845px;
        }
      }
    }
  }
}

@media (max-width: 1590px) {
  .preview {
    &-box {
      &__app {
        &-body {
          width: 810px;
        }
      }
    }
  }
}

@media (max-width: 1540px) {
  .preview {
    &-box {
      max-width: 1540px;
      height: 699px;

      &__app {
        &-body {
          width: 795px;
          right: 0;
          bottom: 78px;
        }
      }

      .app-item__head {
        margin: 120px 0px 35px 45px;

        h1 {
          font-size: 28px;
          line-height: 125%;
        }
      }

      &__exp {
        height: 707px;
        width: 900px;
      }
    }
  }

  .image {
    &-bgApp {
      height: 86%;
      width: 723px;
      top: 107px;
    }

    &-phones {
      width: 620px;
      top: -40px;
      left: 123px;
    }
  }

  .button-preview {
    padding: 15px;
    font-size: 22px;
    width: 65%;
  }
}

@media (max-width: 1500px) {
  .preview {
    &-box {
      &__app {
        &-body {
          width: 780px;
        }
      }
    }
  }

  .image {
    &-phones {
      width: 620px;
      top: -40px;
      left: 105px;
    }
  }
}

@media (max-width: 1480px) {
  .preview {
    margin-top: 73px;
    &-box {
      &__app {
        &-body {
          width: 737px;
          bottom: 89px;

          p {
            color: white;
            font-size: 22px;
          }

          span {
            color: #ea5d2f;
            font-size: 22px;
          }
        }
      }
    }
  }

  .image {
    &-phones {
      width: 620px;
      top: -40px;
      left: 85px;
    }
  }
}

@media (max-width: 1420px) {
  .preview {
    &-box {
      &__app {
        &-body {
          width: 730px;
        }
      }
    }
  }
}

@media (max-width: 1370px) {
  .preview {
    &-box {
      max-width: 1370px;
      height: 650px;

      &__app {
        &-body {
          width: 715px;
          right: 0;
          bottom: 78px;
          background-color: transparent;
        }
      }

      .app-item__head {
        margin: 110px 0px 30px 45px;

        h1 {
          font-size: 26px;
          line-height: 125%;
        }
      }

      &__exp {
        height: 644px;
        width: 900px;
      }
    }
  }

  .image {
    &-bgApp {
      height: 85%;
      width: 634px;
      top: 91px;
    }

    &-phones {
      width: 536px;
      top: -42px;
      left: 120px;
    }
  }

  .button-preview {
    padding: 12px;
    font-size: 21px;
    width: 65%;
  }
}

@media (max-width: 1290px) {
  .preview {
    &-box {
      max-width: 1290px;
      height: 650px;

      &__app {
        &-body {
          width: 693px;
          right: 0;
          bottom: 117px;
          background-color: #0f112f2f;
          padding: 10px 0;

          p {
            font-size: 17px;
            line-height: 130%;
          }

          span {
            font-size: 17px;
            line-height: 130%;
          }
        }
      }

      .app-item__head {
        margin: 100px 0px 20px 45px;

        h1 {
          font-size: 23px;
          line-height: 125%;
        }
      }

      &__exp {
        height: 605px;
        width: 900px;
      }
    }
  }

  .image {
    &-bgApp {
      height: 85%;
      width: 558px;
      top: 53px;
    }

    &-phones {
      width: 525px;
      top: -44px;
      left: 96px;
    }
  }

  .button-preview {
    padding: 11px;
    font-size: 16px;
    width: 65%;
  }
}

@media (max-width: 1220px) {
  .preview {
    &-box {
      height: 490px;
      padding: 0;
      &__app {
        height: 490px;
        width: 510px;
        z-index: 20;
        background: white;

        &-item {
          margin: 15px 0px 35px 0px;

          .app-item__head {
            margin: 80px 0px 17px 35px;
            h1 {
              font-size: 20px !important;
            }
          }
        }

        &-body {
          width: 100%;
          background-color: #0f112fd0;
          bottom: -30px;
        }
      }

      &__exp {
        height: 521px;
        overflow: hidden;
        width: 100%;
        position: absolute;
      }
    }

    .image {
      &-bgApp {
        width: 511px;
        height: 91%;
        top: 75px;
        left: 0px;
      }

      &-bgPhone {
        width: 40%;
      }

      &-phones {
        width: 722px;
        height: auto;
        top: -45px;
        left: 561px;
      }
    }
  }

  .button-preview {
    width: 60%;
    font-size: 13px;
    padding: 10px;
    margin-left: 30px;
  }
}

@media (max-width: 930px) {
  .preview {
    margin-top: 67px;
    &-box {
      &__app {
        width: 425px;

        .app-item__head {
          margin: 121px 0px 17px 30px;
          h1 {
            font-size: 20px !important;
          }
        }

        &-body {
          width: 100%;
          background-color: #0f112faf;

          p {
            font-size: 16px;
          }

          span {
            font-size: 16px;
          }
        }
      }
    }

    .image {
      &-bgApp {
        width: 426px;
        height: 69%;
        top: 182px;
      }

      &-phones {
        width: 66%;
        height: auto;
        top: 6px;
        left: 420px;
      }
    }
  }
}

@media (max-width: 610px) {
  .preview {
    &-box {
      height: 550px;
      &__app {
        width: 290px;

        .app-item__head {
          margin: 150px 0px 26px 15px;

          h1 {
            font-size: 15px !important;
          }
        }

        &-body {
          width: 100%;
          background-color: rgb(15 17 47 / 80%);
          bottom: -1px;

          p {
            font-size: 15px;
          }

          span {
            font-size: 15px;
          }
        }
      }

      &__exp {
        height: 550px;
      }
    }

    .image {
      &-bgApp {
        left: -66px;
        width: 357px;
        height: 68%;
        top: auto;
      }

      &-phones {
        left: 39% !important;
        right: 0;
        top: 30px;
        height: auto;
        width: 80%;
      }
    }

    .button-preview {
      width: 70%;
      font-size: 12px;
      padding: 9px;
      margin-left: 8px;
    }
  }
}

@media (max-width: 420px) {
  .preview {
    &-box {
      height: 420px;
      &__app {
        width: 230px;

        &-item {
          margin: 20px 0px 20px 5px;
        }

        .app-item__head {
          margin: 86px 0px 15px 5px;
          h1 {
            font-size: 13px !important;
          }
        }

        &-body {
          width: 100%;
          background-color: rgb(15 17 47 / 80%);
          bottom: -1px;

          p {
            font-size: 12px;
          }

          span {
            font-size: 12px;
          }
        }
      }

      &__exp {
        height: 420px;
      }
    }

    .image {
      &-bgApp {
        width: 302px;
        height: 79%;
        top: auto;
        left: -72px;
      }
      &-phones {
        top: -14px;
        left: 46%;
        width: 97%;
        right: 0;
      }
    }

    .button-preview {
      width: 80%;
      font-size: 12px;
      padding: 10px;
      margin-left: 5px;
    }
  }

  .button-preview {
    width: 70%;
    font-size: 11px;
    padding: 9px;
    margin-left: 4px;
  }
}

@media (max-width: 376px) {
  .preview {
    overflow: hidden;
    &-box {
      max-width: 376px;
      height: 420px;
      &__app {
        width: 328px;

        &-item {
          margin: 55px 10px 20px 55px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .app-item__head {
          margin: 3px 0px 15px 0px;
          h1 {
            font-size: 15px !important;
            text-align: center;
          }
        }

        &-body {
          p {
            font-size: 13px;
          }

          span {
            font-size: 13px;
          }
        }
      }

      &__exp {
        display: none;
      }
    }

    .image {
      &-bgApp {
        width: 100%;
        height: 100%;
        top: auto;
        left: 0;
      }

      &-phones {
        display: none;
      }
    }

    .button-preview {
      width: 65%;
      font-size: 12px;
      padding: 8px;
      margin-left: 0px;
    }
  }
}

@media (max-width: 356px) {
  .preview {
    overflow: hidden;
    &-box {
      max-width: 376px;
      height: 420px;
      &__app {
        width: 328px;

        &-item {
          margin: 55px 10px 20px 45px;
        }

        .app-item__head {
          margin: 3px 0px 15px 0px;
        }
      }
    }

    .button-preview {
      width: 65%;
      font-size: 12px;
      padding: 8px;
      margin-left: 0px;
    }
  }
}

@media (max-width: 350px) {
  .preview {
    overflow: hidden;
    &-box {
      max-width: 376px;
      height: 420px;
      &__app {
        width: 328px;

        &-item {
          margin: 55px 10px 20px 35px;
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .preview {
    overflow: hidden;
    &-box {
      max-width: 376px;
      height: 420px;
      &__app {
        width: 328px;

        &-item {
          margin: 55px 10px 20px 20px;
        }
      }
    }
  }
}
