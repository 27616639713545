.checkboxCircle-box{
  position: relative;
  z-index: 2;
}

.checkboxCircle {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkboxCircle+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.checkboxCircle+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid white;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60% 60%;
}

.checkboxCircle:not(:disabled):active+label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.checkboxCircle:checked+label::before {
  border-color: rgb(255, 255, 255);
  background-color: rgba(248, 240, 240, 0.225);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.checkboxCircle-label{
color: rgb(255, 255, 255);
font-size: 29px;
font-weight: 500;
line-height: 140%;
}

@media (max-width: 1570px) {
  .checkboxCircle-label{
    font-size: 22px;
    line-height: 130%;
    }
}


@media (max-width: 1245px) {
  .checkboxCircle-label{
    font-size: 17px;
    line-height: 120%;
    }
}

@media (max-width: 490px) {
  .checkboxCircle-label{
    font-size: 15px;
    line-height: 100%;
    }
}

@media (max-width: 375px) {
  .checkboxCircle-label{
    font-size: 14px;
    line-height: 100%;
    }
}